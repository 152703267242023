import paymentService from '@/store/services/paymentService'
import opApiService from '@/store/services/opApiService'
import paymentTypeService from '@/store/services/paymentTypeService'

const state = {
  field: {
    base_amount: null,
    amount: null,
    svc_number: null,
    road_id: null,
    payment_type_id: null,
    reference: null,
    op_amount: null,
    destination_id: null,
    transaction_id: null,
    user_id: null,
    op_api_id: null,
    paginate: 10,
  },
  base_amount_field: null,
  amount_field: null,
  user_id_field: null,
  op_api_id_field: null,
  paginate_field: null,
  svc_number_field: null,
  road_id_field: null,
  payment_type_id_field: null,
  reference_field: null,
  op_amount_field: null,
  destination_id_field: null,
  transaction_id_field: null,
  metaPayment: null,
  metaOpApi: null,
  metaPaymentType: null,
  payment: null,
}

const getters = {
  opApis: state => (state.metaOpApi ? state.metaOpApi.data : []),
  paymentTypes: state => (state.metaPaymentType ? state.metaPaymentType.data : []),
  payments: state => (state.metaPayment ? state.metaPayment.data : []),
  current_page: state => (state.metaPayment ? state.metaPayment.current_page : 0),
  last_page: state => (state.metaPayment ? state.metaPayment.last_page : 0),
  per_page: state => (state.metaPayment ? state.metaPayment.per_page : 0),
}

const actions = {
  getpaymentTypes({ commit }) {
    return paymentTypeService.getPaymentTypes(1, { paginate: 100 }).then(({ data }) => {
      commit('SET_META_PAYMENT_TYPE', data.result)

      /* const items = []
      data.result.forEach(elt => {
        items.push({ text: elt.operator.label, value: elt.id })
      })
      commit('SET_META_PAYMENT_TYPE', items) */

      return data
    })
  },

  getOpApis({ commit }) {
    return opApiService.getOpApis(1, { paginate: 100 }).then(({ data }) => {
      commit('SET_META_OP_API', data.result)

      return data
    })
  },

  // eslint-disable-next-line no-shadow
  getPaymentsList({ commit, state }, { page, field }) {
    if (state.metaPayment && state.metaPayment.current_page === page
      && state.base_amount_field === field.base_amount && state.paginate_field === field.paginate
      && state.svc_number_field === field.svc_number && state.road_id_field === field.road_id
      && state.payment_type_id_field === field.payment_type_id && state.reference_field === field.reference
      && state.op_amount_field === field.op_amount && state.destination_id_field === field.destination_id
      && state.transaction_id_field === field.transaction_id && state.amount_field === field.amount
      && state.user_id_field === field.user_id && state.op_api_id_field === field.op_api_id) return state.metaPayment

    return paymentService.getPayments(page, field).then(({ data }) => {
      commit('SET_META_PAYMENT', data.result)
      commit('SET_BASE_AMOUNT_FIELD', field.base_amount)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_OP_API_ID_FIELD', field.op_api_id)
      commit('SET_AMOUNT_FIELD', field.amount)
      commit('SET_SVC_NUMBER_FIELD', field.svc_number)
      commit('SET_ROAD_ID_FIELD', field.road_id)
      commit('SET_PAYMENT_TYPE_ID_FIELD', field.payment_type_id)
      commit('SET_REFERENCE_FIELD', field.reference)
      commit('SET_OP_AMOUNT_FIELD', field.op_amount)
      commit('SET_DESTINATION_ID_FIELD', field.destination_id)
      commit('SET_FIELD', field)
      commit('SET_USER_ID_FIELD', field.user_id)
      commit('SET_TRANSACTION_ID_FIELD', field.transaction_id)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  getPaymentById({ commit, state }, id) {
    return paymentService.getPayment(id).then(({ data }) => {
      commit('SET_PAYMENT', data.result)

      return data
    })
  },
}

const mutations = {
  // eslint-disable-next-line no-shadow
  SET_PAYMENT(state, item) {
    state.payment = item
  },
  // eslint-disable-next-line no-shadow
  SET_USER_ID_FIELD(state, item) {
    state.user_id_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_SVC_NUMBER_FIELD(state, item) {
    state.svc_number_field = item
  },

  // eslint-disable-next-line no-shadow
  SET_ROAD_ID_FIELD(state, item) {
    state.road_id_field = item
  },

  // eslint-disable-next-line no-shadow
  SET_TRANSACTION_ID_FIELD(state, item) {
    state.transaction_id_field = item
  },

  // eslint-disable-next-line no-shadow
  SET_PAYMENT_TYPE_ID_FIELD(state, item) {
    state.payment_type_id_field = item
  },

  // eslint-disable-next-line no-shadow
  SET_REFERENCE_FIELD(state, item) {
    state.reference_field = item
  },

  // eslint-disable-next-line no-shadow
  SET_OP_AMOUNT_FIELD(state, item) {
    state.op_amount_field = item
  },

  // eslint-disable-next-line no-shadow
  SET_DESTINATION_ID_FIELD(state, item) {
    state.destination_id_field = item
  },

  // eslint-disable-next-line no-shadow
  SET_AMOUNT_FIELD(state, item) {
    state.amount_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_PAGINATE_FIELD(state, paginante) {
    state.paginate_field = paginante
  },

  // eslint-disable-next-line no-shadow
  SET_OP_API_ID_FIELD(state, item) {
    state.op_api_id_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_BASE_AMOUNT_FIELD(state, item) {
    state.base_amount_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_META_PAYMENT(state, metaPayment) {
    state.metaPayment = metaPayment
  },

  // eslint-disable-next-line no-shadow
  SET_META_OP_API(state, item) {
    state.metaOpApi = item
  },

  // eslint-disable-next-line no-shadow
  SET_META_PAYMENT_TYPE(state, item) {
    state.metaPaymentType = item
  },

  // eslint-disable-next-line no-shadow
  SET_FIELD(state, field) {
    state.field = field
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
