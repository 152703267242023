import axios from '@axios'

export default {
  getOperators(page, field) {
    return axios.post(`platform/operators/list?page=${page}`, field)
  },

  getOperator(id) {
    return axios.get(`platform/operators/${id}`)
  },

  activateOperator(id) {
    return axios.put(`platform/operators/activate/${id}`)
  },

  updateOperator(operator) {
    return axios.put(`platform/operators/${operator.id}`, operator)
  },
}
