import roadService from '@/store/services/roadService'

const state = {
  field: {
    city: null,
    nb_passagers: null,
    start_date: null,
    min_amount: null,
    max_amount: null,
    user_id: null,
    car: null,
    paginate: 10,
    destination: null,
  },
  city_field: null,
  start_date_field: null,
  nb_passagers_field: null,
  min_amount_field: null,
  max_amount_field: null,
  user_id_field: null,
  car_field: null,
  paginate_field: null,
  destination_field: null,
  metaRoad: null,
  road: null,
}

const getters = {
  roads: state => (state.metaRoad ? state.metaRoad.data : []),
  current_page: state => (state.metaRoad ? state.metaRoad.current_page : 0),
  last_page: state => (state.metaRoad ? state.metaRoad.last_page : 0),
  per_page: state => (state.metaRoad ? state.metaRoad.per_page : 0),
}

const actions = {
  // eslint-disable-next-line no-shadow
  getRoadsList({ commit, state }, { page, field }) {
    if (state.metaRoad && state.metaRoad.current_page === page
      && state.city_field === field.city && state.paginate_field === field.paginate
      && state.start_date_field === field.start_date && state.nb_passagers_field === field.nb_passagers
      && state.min_amount_field === field.min_amount && state.max_amount_field === field.max_amount
      && state.user_id_field === field.user_id && state.car_field === field.car
      && state.destination_field === field.destination) return state.metaRoad

    return roadService.getRoads(page, field).then(({ data }) => {
      commit('SET_META_ROAD', data.result)
      commit('SET_CITY_FIELD', field.city)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_START_DATE_FIELD', field.start_date)
      commit('SET_NB_PASSAGERS_FIELD', field.nb_passagers)
      commit('SET_FIELD', field)
      commit('SET_MIN_AMOUNT_FIELD', field.min_amount)
      commit('SET_MAX_AMOUNT_FIELD', field.max_amount)
      commit('SET_USER_ID_FIELD', field.user_id)
      commit('SET_CAR_FIELD', field.car)
      commit('SET_DESTINATION_FIELD', field.destination)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  getRoadById({ commit, state }, id) {
    return roadService.getRoad(id).then(({ data }) => {
      commit('SET_ROAD', data.result)

      return data
    })
  },

}

const mutations = {
  // eslint-disable-next-line no-shadow
  SET_ROAD(state, item) {
    state.road = item
  },
  // eslint-disable-next-line no-shadow
  SET_DESTINATION_FIELD(state, item) {
    state.destination_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_CAR_FIELD(state, item) {
    state.car_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_USER_ID_FIELD(state, item) {
    state.user_id_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_MAX_AMOUNT_FIELD(state, item) {
    state.max_amount_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_MIN_AMOUNT_FIELD(state, item) {
    state.min_amount_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_NB_PASSAGERS_FIELD(state, item) {
    state.nb_passagers_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_START_DATE_FIELD(state, item) {
    state.start_date_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_PAGINATE_FIELD(state, paginante) {
    state.paginate_field = paginante
  },
  // eslint-disable-next-line no-shadow
  SET_CITY_FIELD(state, city) {
    state.city_field = city
  },
  // eslint-disable-next-line no-shadow
  SET_META_ROAD(state, metaRoad) {
    state.metaRoad = metaRoad
  },

  // eslint-disable-next-line no-shadow
  SET_FIELD(state, field) {
    state.field = field
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
