import destinationService from '@/store/services/destinationService'

const state = {
  field: {
    city_id: null,
    road_id: null,
    paginate: 10,
    final: false,
  },
  city_id_field: null,
  paginate_field: null,
  road_id_field: null,
  destination: null,
  metaDestination: null,
}

const getters = {
  destinations: state => (state.metaDestination ? state.metaDestination.data : []),
  current_page: state => (state.metaDestination ? state.metaDestination.current_page : 0),
  last_page: state => (state.metaDestination ? state.metaDestination.last_page : 0),
  per_page: state => (state.metaDestination ? state.metaDestination.per_page : 0),
}

const actions = {
  // eslint-disable-next-line no-shadow
  getDestinationsList({ commit, state }, { page, field }) {
    if (state.metaDestination && state.metaDestination.current_page === page
      && state.paginate_field === field.paginate
      && state.road_id_field === field.road_id) return state.metaDestination

    return destinationService.getDestinations(page, field).then(({ data }) => {
      commit('SET_META', data.result)
      commit('SET_FIELD', field)
      commit('SET_ROAD_ID_FIELD', field.road_id)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  getDestinationById({ commit, state }, destination) {
    return destinationService.getDestination(destination).then(({ data }) => {
      commit('SET_DESTINATION', data.result)

      return data
    })
  },

}

const mutations = {
  // eslint-disable-next-line no-shadow
  SET_DESTINATION(state, item) {
    state.destination = item
  },
  // eslint-disable-next-line no-shadow
  SET_ROAD_ID_FIELD(state, item) {
    state.road_id_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_PAGINATE_FIELD(state, paginante) {
    state.paginate_field = paginante
  },
  // eslint-disable-next-line no-shadow
  SET_META(state, metaDestination) {
    state.metaDestination = metaDestination
  },

  // eslint-disable-next-line no-shadow
  SET_FIELD(state, field) {
    state.field = field
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
