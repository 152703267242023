const userpages = [
  {
    path: '/users/:id/show',
    name: 'showuser',
    component: () => import('@/views/members/UserDetails.vue'),
    meta: {
      layout: 'content',
      title: "Détails d'un utilisateur",
    },
  },
]

export default userpages
