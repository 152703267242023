import carService from '@/store/services/carService'

const state = {
  field: {
    keyword: null,
    user_id: null,
    paginate: 10,
  },
  keyword_field: null,
  paginate_field: null,
  user_id_field: null,
  car: {
    id: null,
    marque: null,
    model: null,
    release_year: null,
    created_at: null,
    updated_at: null,
    deleted_at: null,
  },
  metaCar: null,
}

const getters = {
  cars: state => (state.metaCar ? state.metaCar.data : []),
  current_page: state => (state.metaCar ? state.metaCar.current_page : 0),
  last_page: state => (state.metaCar ? state.metaCar.last_page : 0),
  per_page: state => (state.metaCar ? state.metaCar.per_page : 0),
}

const actions = {
  // eslint-disable-next-line no-shadow
  getCarsList({ commit, state }, { page, field }) {
    if (state.metaCar && state.metaCar.current_page === page
      && state.paginate_field === field.paginate && state.user_id_field === field.user_id
      && state.keyword_field === field.keyword) return state.metaCar

    return carService.getRoads(page, field).then(({ data }) => {
      commit('SET_META_CAR', data.result)
      commit('SET_FIELD', field)
      commit('SET_USER_ID_FIELD', field.user_id)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  getCarById({ commit, state }, car) {
    return carService.getCar(car).then(({ data }) => {
      commit('SET_CAR', data.result)

      return data
    })
  },

}

const mutations = {
  // eslint-disable-next-line no-shadow
  SET_CAR(state, item) {
    state.car = item
  },
  // eslint-disable-next-line no-shadow
  SET_USER_ID_FIELD(state, item) {
    state.user_id_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_PAGINATE_FIELD(state, paginante) {
    state.paginate_field = paginante
  },
  // eslint-disable-next-line no-shadow
  SET_META_CAR(state, metaCar) {
    state.metaCar = metaCar
  },

  // eslint-disable-next-line no-shadow
  SET_FIELD(state, field) {
    state.field = field
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
