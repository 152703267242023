import store from '@/store'
import router from '@/router'

export const notify = (isNotifSuccess, isNotifVisible, notifMessage) => {
  store.state.user.notifMessage = notifMessage
  store.state.user.isNotifSuccess = isNotifSuccess
  store.state.user.isNotifVisible = isNotifVisible
}

export const findPage = () => {
  store.dispatch('user/redirectToMenu').catch(error => {
    console.log("Moi")
    console.log(error)
  })
}

export const showMenu = code => {
  switch (code) {
    case 'VIEWDASHBOARD': return store.state.user.currentUser.menu.dashboard
    case 'VIEWROADS': return store.state.user.currentUser.menu.road
    case 'VIEWDOCUMENTS': return store.state.user.currentUser.menu.document
    case 'VIEWPAYS': return store.state.user.currentUser.menu.payment
    case 'VIEWRECOVERIES': return store.state.user.currentUser.menu.recovery
    case 'VIEWREDUCTCODES': return store.state.user.currentUser.menu.reduct_code
    case 'VIEWMEMBERS': return store.state.user.currentUser.menu.member
    case 'VIEWADMINS': return store.state.user.currentUser.menu.admin
    case 'VIEWROLES': return store.state.user.currentUser.menu.role
    case 'SETTINGS': return store.state.user.currentUser.menu.setting
    case 'VIEWPROXMARS': return store.state.user.currentUser.menu.proximity
    case 'VIEWRECSTATS': return store.state.user.currentUser.menu.recovery_status
    case 'VIEWPAYSTAT': return store.state.user.currentUser.menu.pay_status
    case 'VIEWRECTYPE': return store.state.user.currentUser.menu.rec_type
    case 'VIEWPAYREASONS': return store.state.user.currentUser.menu.pay_reason
    case 'GLOBALVALUES': return store.state.user.currentUser.menu.global_values
    case 'VIEWOPERATORS': return store.state.user.currentUser.menu.operators
    default: return false
  }
}

export const intToCurrency = (locale_string, formatting_options, value) => new Intl.NumberFormat(locale_string === 'fr' ? 'fr-FR' : 'en-EN', formatting_options).format(value)
