import statusRecService from '@/store/services/statusRecService'
import store from '@/store'

const state = {
  field: {
    keyword: null,
    paginate: 10,
  },
  keyword_field: null,
  paginate_field: null,
  metaStatusRec: null,
  statusRec: null,
}

const getters = {
  statuses_rec: state => (state.metaStatusRec ? state.metaStatusRec.data : []),
  current_page: state => (state.metaStatusRec ? state.metaStatusRec.current_page : 0),
  last_page: state => (state.metaStatusRec ? state.metaStatusRec.last_page : 0),
  per_page: state => (state.metaStatusRec ? state.metaStatusRec.per_page : 0),
  total: state => (state.metaStatusRec ? state.metaStatusRec.total : 0),
}

const actions = {
  // eslint-disable-next-line no-shadow
  getStatusesRecList({ commit, state }, { page, field }) {
    if (state.metaStatusRec && state.metaStatusRec.current_page === page
      && state.keyword_field === field.keyword && state.paginate_field === field.paginate) return state.metaStatusRec

    return statusRecService.getStatusesRecoveryList(page, field).then(({ data }) => {
      commit('SET_META_STATUS_REC', data.result)
      commit('SET_KEYWORD_FIELD', field.keyword)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_FIELD', field)

      return data
    })
  },

  refreshStatusesRecList({ commit, state }, { page, field }) {
    return statusRecService.getStatusesRecoveryList(page, field).then(({ data }) => {
      commit('SET_META_STATUS_REC', data.result)
      commit('SET_KEYWORD_FIELD', field.keyword)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_FIELD', field)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  getStatusRecById({ commit, state }, id) {
    return statusRecService.getStatusRecovery(id).then(({ data }) => {
      commit('SET_STATUS_REC', data.result)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  updateStatusRec({ commit, state }, field) {
    return statusRecService.updateStatusRecovery(field).then(({ data }) => {
      commit('UPDATE_STATUS_REC', { statusRec: data.result, item: field })

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  addStatusRec({ commit, state }, field) {
    return statusRecService.addStatusRecovery(field).then(({ data }) => {
      commit('ADD_STATUS_REC', data.result)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  deleteStatusRec({ commit, state }, field) {
    return statusRecService.deleteStatusRecovery(field).then(({ data }) => {
      commit('UPDATE_STATUS_REC', { statusRec: data.result, item: field })

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  restoreStatusRec({ commit, state }, field) {
    return statusRecService.restoreStatusRecovery(field).then(({ data }) => {
      commit('UPDATE_STATUS_REC', { statusRec: data.result, item: field })

      return data
    })
  },

}

const mutations = {

  // eslint-disable-next-line no-shadow
  UPDATE_STATUS_REC(state, { statusRec, item }) {
    if (state.metaStatusRec) { state.metaStatusRec.data.splice(state.metaStatusRec.data.findIndex(t => t.id === item.id), 1, statusRec) }
  },

  // eslint-disable-next-line no-shadow
  ADD_STATUS_REC(state, statusRec) {
    state.metaStatusRec.data.unshift(statusRec)
  },

  // eslint-disable-next-line no-shadow
  SET_STATUS_REC(state, item) {
    state.statusRec = item
  },
  // eslint-disable-next-line no-shadow
  SET_OSM_ROUTE(state, item) {
    state.osmRoute = item
  },
  // eslint-disable-next-line no-shadow
  SET_DESTINATION_FIELD(state, item) {
    state.destination_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_CAR_FIELD(state, item) {
    state.car_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_USER_ID_FIELD(state, item) {
    state.user_id_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_MAX_AMOUNT_FIELD(state, item) {
    state.max_keyword_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_MIN_AMOUNT_FIELD(state, item) {
    state.admin_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_PAYMENT_ID_FIELD(state, item) {
    state.payment_id_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_USER_FIELD(state, item) {
    state.user_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_PAGINATE_FIELD(state, paginante) {
    state.paginate_field = paginante
  },
  // eslint-disable-next-line no-shadow
  SET_KEYWORD_FIELD(state, keyword) {
    state.keyword_field = keyword
  },
  // eslint-disable-next-line no-shadow
  SET_META_STATUS_REC(state, metaStatusRec) {
    state.metaStatusRec = metaStatusRec
  },

  // eslint-disable-next-line no-shadow
  SET_FIELD(state, field) {
    state.field = field
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
