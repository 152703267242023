import statusRecoveryService from '@/store/services/statusRecoveryService'

const state = {
  field: {
    recovery_id: null,
    is_active: null,
    paginate: 1000,
  },
  paginate_field: null,
  is_active_field: null,
  recovery_id_field: null,
  metaStatusRecovery: null,
  statusRec: null,
}

const getters = {
  statuses_recovery: state => (state.metaStatusRecovery ? state.metaStatusRecovery.data : []),
  current_page: state => (state.metaStatusRecovery ? state.metaStatusRecovery.current_page : 0),
  last_page: state => (state.metaStatusRecovery ? state.metaStatusRecovery.last_page : 0),
  per_page: state => (state.metaStatusRecovery ? state.metaStatusRecovery.per_page : 0),
}

const actions = {
  // eslint-disable-next-line no-shadow
  getStatusesRecList({ commit, state }, { page, field }) {
    if (state.metaStatusRecovery && state.metaStatusRecovery.current_page === page
      && state.paginate_field === field.paginate
      && state.is_active_field === field.is_active && state.recovery_id_field === field.recovery_id) return state.metaStatusRecovery

    return statusRecoveryService.getStatusRecoveryList(page, field).then(({ data }) => {
      commit('SET_META_STATUS_RECOVERY', data.result)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_IS_ACTIVE_FIELD', field.is_active)
      commit('SET_RECOVERY_ID_FIELD', field.recovery_id)
      commit('SET_FIELD', field)

      return data
    })
  },

}

const mutations = {

  // eslint-disable-next-line no-shadow
  SET_PAGINATE_FIELD(state, paginante) {
    state.paginate_field = paginante
  },

  // eslint-disable-next-line no-shadow
  SET_IS_ACTIVE_FIELD(state, is_active) {
    state.is_active_field = is_active
  },

  // eslint-disable-next-line no-shadow
  SET_RECOVERY_ID_FIELD(state, recovery_id) {
    state.recovery_id_field = recovery_id
  },
  // eslint-disable-next-line no-shadow
  SET_META_STATUS_RECOVERY(state, metaStatusRecovery) {
    state.metaStatusRecovery = metaStatusRecovery
  },

  // eslint-disable-next-line no-shadow
  SET_FIELD(state, field) {
    state.field = field
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
