<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        bottom
        color="success"
        overlap
        offset-x="12"
        offset-y="12"
        class="ms-4"
        dot
      >
        <v-avatar
          size="40px"
          v-bind="attrs"
          color="primary"
          class="v-avatar-light-bg primary--text"
          v-on="on"
        >
          <v-img
            v-if="currentUser.profile_picture_path"
            :src="currentUser.profile_picture_path"
          ></v-img>
          <span v-else>{{ currentUser.name ? currentUser.name.slice(0,2).toUpperCase() : null }}</span>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >
          <v-avatar
            size="40px"
            color="primary"
            class="v-avatar-light-bg primary--text"
          >
            <v-img
              v-if="currentUser.profile_picture_path"
              :src="currentUser.profile_picture_path"
            ></v-img>
            <span v-else-if="currentUser.name">{{ currentUser.name ? currentUser.name.slice(0,2).toUpperCase() : null }}</span>
            <v-img
              v-else
              :src="require('@/assets/images/avatars/1.png')"
            />
          </v-avatar>
        </v-badge>
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align:middle"
        >
          <span class="text--primary font-weight-semibold mb-n1">
            {{ currentUser.name }}
          </span>
          <small class="text--disabled text-capitalize">{{ currentUser.role.label }}</small>
        </div>
      </div>

      <v-divider></v-divider>

      <!-- Profile -->
      <v-list-item @click.prevent="getProfile">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Profil</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-2"></v-divider>

      <!-- Logout -->
      <v-list-item @click.prevent="logout">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Déconnexion</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from '@mdi/js'

import { useRouter } from '@core/utils'
import { mapState } from 'vuex'
import { onMounted, watch } from '@vue/composition-api'
import store from '../store'

export default {
  computed: {
    ...mapState('user', ['currentUser']),
  },

  setup() {
    const { router } = useRouter()
    const logout = () => {
      store.dispatch('user/logout', localStorage.getItem('device_token') !== null ? localStorage.getItem('device_token') : null).then(() => {
        router.push('/login')
      })
    }

    const getProfile = () => {
      console.log(store.state.user.refreshUserId)
      if (router.currentRoute.name !== 'showuser') {
        if (router.currentRoute.params.id !== store.state.user.currentUser.id) {
          router.push({ name: 'showuser', params: { id: store.state.user.currentUser.id } })
        } else {
          router.replace({ name: 'showuser', params: { id: store.state.user.currentUser.id } })
          store.state.user.refreshUserId = store.state.user.currentUser.id
          store.state.user.refreshUserCount ++
        }
      } else if (router.currentRoute.params.id !== store.state.user.currentUser.id) {
        router.replace({ name: 'showuser', params: { id: store.state.user.currentUser.id } })
        store.state.user.refreshUserId = store.state.user.currentUser.id
        store.state.user.refreshUserCount ++
      }
      console.log(store.state.user.refreshUserId)
    }

    const getCurrentUser = () => {
      store.dispatch('user/getCurrentUser', localStorage.getItem('device_token') !== null ? localStorage.getItem('device_token') : null).catch(error => {
        console.log(error)
      })
    }

    onMounted(() => {
      getCurrentUser()
    })

    return {
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
      },
      logout,
      getProfile,
    }
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
