import axios from '@axios'

export default {
  getCities(page, field) {
    return axios.post(`platform/roads/cities/list?page=${page}`, field)
  },

  searchCity(field) {
    return axios.post('platform/roads/cities/search/map', field)
  },
}
