import reductCodeUserService from '@/store/services/reductCodeUserService'

const state = {
  field: {
    user_id: null,
    reduct_code_id: null,
    paginate: 10,
    user: null,
  },
  reduct_code_id_field: null,
  user_id_field: null,
  user_field: null,
  paginate_field: null,
  metaReductCodeUser: null,
  reductCodeUser: null,
}

const getters = {
  reduct_code_users: state => (state.metaReductCodeUser ? state.metaReductCodeUser.data : []),
  current_page: state => (state.metaReductCodeUser ? state.metaReductCodeUser.current_page : 0),
  last_page: state => (state.metaReductCodeUser ? state.metaReductCodeUser.last_page : 0),
  per_page: state => (state.metaReductCodeUser ? state.metaReductCodeUser.per_page : 0),
}

const actions = {
  // eslint-disable-next-line no-shadow
  getReductCodeUsersList({ commit, state }, { page, field }) {
    if (state.metaReductCodeUser && state.metaReductCodeUser.current_page === page
      && state.reduct_code_id_field === field.reduct_code_id && state.paginate_field === field.paginate
      && state.user_id_field === field.user_id && state.user_field === field.user) return state.metaReductCodeUser

    return reductCodeUserService.getReductCodeUsersList(page, field).then(({ data }) => {
      commit('SET_META_REDUCT_CODE', data.result)
      commit('SET_REDUCT_C0DE_ID_FIELD', field.reduct_code_id)
      commit('SET_USER_ID_FIELD', field.user_id_field)
      commit('SET_USER_FIELD', field.user_field)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_FIELD', field)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  getReductCodeUserById({ commit, state }, id) {
    return reductCodeUserService.getReductCodeUser(id).then(({ data }) => {
      commit('SET_REDUCT_CODE_USER', data.result)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  deleteReductCodeUser({ commit, state }, field) {
    return reductCodeUserService.deleteReductCodeUser(field).then(({ data }) => {
      commit('UPDATE_REDUCT_CODE_USER', { reductCodeUser: data.result, item: field })

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  restoreReductCode({ commit, state }, field) {
    return reductCodeUserService.restoreReductCodeUser(field).then(({ data }) => {
      commit('UPDATE_REDUCT_CODE_USER', { reductCodeUser: data.result, item: field })

      return data
    })
  },

}

const mutations = {

  // eslint-disable-next-line no-shadow
  UPDATE_REDUCT_CODE_USER(state, { reductCodeUser, item }) {
    if (state.metaReductCodeUser) { state.metaReductCodeUser.data.splice(state.metaReductCodeUser.data.findIndex(t => t.id === item.id), 1, reductCodeUser) }
  },

  // eslint-disable-next-line no-shadow
  ADD_REDUCT_CODE(state, reductCodeUser) {
    state.metaReductCodeUser.data.unshift(reductCodeUser)
  },

  // eslint-disable-next-line no-shadow
  SET_REDUCT_CODE_USER(state, item) {
    state.reductCodeUser = item
  },

  // eslint-disable-next-line no-shadow
  SET_USER_ID_FIELD(state, item) {
    state.user_id_field = item
  },

  // eslint-disable-next-line no-shadow
  SET_USER_FIELD(state, item) {
    state.user_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_PAGINATE_FIELD(state, paginante) {
    state.paginate_field = paginante
  },

  // eslint-disable-next-line no-shadow
  SET_REDUCT_C0DE_ID_FIELD(state, reduct_code_id) {
    state.reduct_code_id_field = reduct_code_id
  },
  // eslint-disable-next-line no-shadow
  SET_META_REDUCT_CODE(state, metaReductCodeUser) {
    state.metaReductCodeUser = metaReductCodeUser
  },

  // eslint-disable-next-line no-shadow
  SET_FIELD(state, field) {
    state.field = field
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
