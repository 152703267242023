import paymentStatusService from '@/store/services/paymentStatusService'

const state = {
  field: {
    payment_id: null,
    paginate: 20,
  },
  payment_id_field: null,
  paginate_field: null,
  metaPaymentStatus: null,
}

const getters = {
  paymentStatuses: state => (state.metaPaymentStatus ? state.metaPaymentStatus.data : []),
  current_page: state => (state.metaPaymentStatus ? state.metaPaymentStatus.current_page : 0),
  last_page: state => (state.metaPaymentStatus ? state.metaPaymentStatus.last_page : 0),
  per_page: state => (state.metaPaymentStatus ? state.metaPaymentStatus.per_page : 0),
}

const actions = {

  // eslint-disable-next-line no-shadow
  getPaymentStatusList({ commit, state }, { page, field }) {
    if (state.metaPaymentStatus && state.metaPaymentStatus.current_page === page
      && state.paginate_field === field.paginate && state.payment_id_field === field.payment_id) return state.metaPaymentStatus

    return paymentStatusService.getPaymentStatus(page, field).then(({ data }) => {
      commit('SET_META_PAYMENT_STATUS', data.result)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_PAYMENT_ID_FIELD', field.payment_id)
      commit('SET_FIELD', field)

      return data
    })
  },
}

const mutations = {
  // eslint-disable-next-line no-shadow
  SET_PAGINATE_FIELD(state, paginante) {
    state.paginate_field = paginante
  },

  // eslint-disable-next-line no-shadow
  SET_PAYMENT_ID_FIELD(state, item) {
    state.payment_id_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_META_PAYMENT_STATUS(state, metaPaymentStatus) {
    state.metaPaymentStatus = metaPaymentStatus
  },

  SET_FIELD(state, field) {
    state.field = field
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
