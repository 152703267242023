import axios from '@axios'

export default {
  getRecoveries(page, field) {
    return axios.post(`platform/recoveries/list?page=${page}`, field)
  },

  getRecovery(id) {
    return axios.get(`platform/recoveries/${id}`)
  },

  validateRecovery(field) {
    return axios.post('platform/payments/validate/recovery', field)
  },
}
