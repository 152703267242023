import axios from '@axios'

export default {
  getPayReasonList(page, field) {
    return axios.post(`admin/pay_reasons/list?page=${page}`, field)
  },

  getPayReason(id) {
    return axios.get(`admin/pay_reasons/${id}`)
  },

  updatePayReason(field) {
    return axios.put(`admin/pay_reasons/${field.id}`, field)
  },
}
