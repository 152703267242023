import {
  mdiApplicationSettings, mdiViewDashboard, mdiAccountGroup, mdiRoad, mdiAccountSupervisorCircle, mdiCash,
  mdiCogs, mdiHumanGreetingProximity, mdiContactlessPayment, mdiCashMultiple, mdiListStatus, mdiCodeBraces, mdiFormatListBulletedType,
  mdiShapeOutline, mdiBasketFill, mdiAccountGroupOutline, mdiFolder,mdiMapSearch, mdiBullhorn
} from '@mdi/js'
import store from '@/store'

const menuTab = []

if (store.state.user.currentUser.menu.dashboard === true) {
  menuTab.push({
    title: 'Dashboards',
    icon: mdiViewDashboard,
    to: 'home',
    show: localStorage.getItem('dashboard') === 'true',
  })
}

export default [
  {
    title: 'Dashboards',
    icon: mdiViewDashboard,
    to: 'home',
    show: false,
    key: 'VIEWDASHBOARD',
  },
  {
    title: 'Roads',
    icon: mdiRoad,
    to: 'roads',
    show: false,
    key: 'VIEWROADS',
  },
  {
    title: 'Payments',
    icon: mdiCash,
    to: 'paiements',
    show: false,
    key: 'VIEWPAYS',
  },
  {
    title: 'Documents',
    icon: mdiFolder,
    to: 'documents',
    show: false,
    key: 'VIEWDOCUMENTS',
  },
  {
    title: 'Research',
    icon: mdiMapSearch,
    to: 'searches',
    show: false,
    key: 'VIEWSEARCH',
  },
  {
    title: 'Recoveries',
    icon: mdiCashMultiple,
    to: 'recoveries',
    show: false,
    key: 'VIEWRECOVERIES',
  },
  {
    title: 'reduction_codes',
    icon: mdiCodeBraces,
    to: 'reduct_codes',
    show: false,
    key: 'VIEWREDUCTCODES',
  },
  {
    title: 'Campagnes',
    icon: mdiBullhorn,
    to: 'campaigns',
    show: false,
    key: 'CAMPAIGNS',
  },
  {
    title: 'Members',
    icon: mdiAccountGroup,
    to: 'members',
    show: false,
    key: 'VIEWMEMBERS',
  },
  {
    title: 'Administrators',
    icon: mdiAccountSupervisorCircle,
    to: 'admins',
    show: false,
    key: 'VIEWADMINS',
  },
  {
    title: 'Roles',
    icon: mdiAccountGroupOutline,
    to: 'roles',
    show: false,
    key: 'VIEWROLES',
  },

  {
    title: 'Settings',
    icon: mdiApplicationSettings,
    show: false,
    key: 'SETTINGS',
    children: [
      {
        title: 'Proximity',
        to: 'proximargin',
        icon: mdiHumanGreetingProximity,
        key: 'VIEWPROXMARS',
      },
      {
        title: 'Recovery_status',
        to: 'statuses_rec',
        icon: mdiListStatus,
        key: 'VIEWRECSTATS',
      },
      {
        title: 'Payment_status',
        to: 'statuses',
        icon: mdiFormatListBulletedType,
        key: 'VIEWPAYSTAT',
      },
      {
        title: 'Recovery_types',
        to: 'rec_types',
        icon: mdiShapeOutline,
        key: 'VIEWRECTYPE',
      },
      {
        title: 'Pay_reason',
        to: 'pay_reasons',
        icon: mdiBasketFill,
        key: 'VIEWPAYREASONS',
      },
      {
        title: 'Global_values',
        to: 'setting',
        icon: mdiCogs,
        key: 'GLOBALVALUES',
      },
      {
        title: 'Operators',
        to: 'operator',
        icon: mdiContactlessPayment,
        key: 'VIEWOPERATORS',
      },
    ],
  },
]
