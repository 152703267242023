import { notify } from '@core/dynamicComponents/dynamicComponents'
import operatorService from '@/store/services/operatorService'

const state = {
  field: {
    keyword: null,
    paginate: 10,
  },
  keyword_field: null,
  paginate_field: null,
  metaOperator: null,
  operator: {
    logo_path: null,
  },
}

const getters = {
  operators: state => (state.metaOperator ? state.metaOperator.data : []),
  current_page: state => (state.metaOperator ? state.metaOperator.current_page : 0),
  last_page: state => (state.metaOperator ? state.metaOperator.last_page : 0),
  per_page: state => (state.metaOperator ? state.metaOperator.per_page : 0),
  total: state => (state.metaOperator ? state.metaOperator.total : 0),
}

const actions = {
  // eslint-disable-next-line no-shadow
  getOperatorsList({ commit, state }, { page, field }) {
    if (state.metaOperator && state.metaOperator.current_page === page
      && state.keyword_field === field.keyword && state.paginate_field === field.paginate) return state.metaOperator

    return operatorService.getOperators(page, field).then(({ data }) => {
      commit('SET_META_OPERATOR', data.result)
      commit('SET_KEYWORD_FIELD', field.keyword)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_FIELD', field)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  getOperatorById({ commit, state }, id) {
    return operatorService.getOperator(id).then(({ data }) => {
      commit('SET_OPERATOR', data.result)

      return data
    })
  },

  updateOperator({ commit, state }, operator) {
    return operatorService.updateOperator(operator).then(({ data }) => {
      commit('UPDATE_OPERATOR', { setting: data.result, item: operator })
      notify(true, true, data.message)

      return data
    })
  },

  activateOperator({ commit, state }, operator) {
    return operatorService.activateOperator(operator.id).then(({ data }) => {
      commit('UPDATE_OPERATOR', { setting: data.result, item: operator })

      return data
    })
  },
}

const mutations = {
  UPDATE_OPERATOR(state, { setting, item }) {
    if (state.metaOperator) { state.metaOperator.data.splice(state.metaOperator.data.findIndex(t => t.id === item.id), 1, setting) }
  },
  // eslint-disable-next-line no-shadow
  SET_OPERATOR(state, item) {
    state.operator = item
  },
  // eslint-disable-next-line no-shadow
  SET_DESTINATION_FIELD(state, item) {
    state.destination_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_USER_ID_FIELD(state, item) {
    state.user_id_field = item
  },

  // eslint-disable-next-line no-shadow
  SET_NB_PASSAGERS_FIELD(state, item) {
    state.nb_passagers_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_START_DATE_FIELD(state, item) {
    state.start_date_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_PAGINATE_FIELD(state, paginante) {
    state.paginate_field = paginante
  },
  // eslint-disable-next-line no-shadow
  SET_KEYWORD_FIELD(state, keyword) {
    state.keyword_field = keyword
  },
  // eslint-disable-next-line no-shadow
  SET_META_OPERATOR(state, metaOperator) {
    state.metaOperator = metaOperator
  },

  // eslint-disable-next-line no-shadow
  SET_FIELD(state, field) {
    state.field = field
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
