const reductCodePages = [
  {
    path: '/reduct_codes',
    name: 'reduct_codes',
    component: () => import('@/views/reductCode/ReductCode.vue'),
    meta: {
      layout: 'content',
      title: 'Code de réduction',
    },
  },
  {
    path: '/reduct_codes/:id/show',
    name: 'showreductcode',
    component: () => import('@/views/reductCode/ReductCodeDetails.vue'),
    meta: {
      layout: 'content',
      title: "Détails d'un code de réduction",
    },
  },
]

export default reductCodePages
