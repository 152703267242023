const campaignPages = [
  {
    path: '/campaigns',
    name: 'campaigns',
    component: () => import('@/views/campaign/Campaign.vue'),
    meta: {
      layout: 'content',
      title: 'Campaign',
    },
  },
  {
    path: '/campaigns/:id/show',
    name: 'showcampaign',
    component: () => import('@/views/campaign/CampaignDetails.vue'),
    meta: {
      layout: 'content',
      title: "Détails de la campaign",
    },
  },
]

export default campaignPages
