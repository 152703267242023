<template>
  <div>
    <v-menu
      offset-y
      nudge-bottom="22"
      min-width="175"
      left
      :elevation="$vuetify.theme.dark ? 9 : 8"
    >
      <!-- Activator -->
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          class="d-flex align-center"
          v-on="on"
        >
          <v-img
            :src="require(`@/assets/images/flags/${$i18n.locale}.png`)"
            :alt="$i18n.locale"
            height="14px"
            width="22px"
            class="me-2"
          ></v-img>
          <span v-if="$vuetify.breakpoint.smAndUp">{{ locales.find(l => l.locale === $i18n.locale).title }}</span>
        </div>
      </template>

      <!-- Options List -->
      <v-list>
        <v-list-item-group
          :value="$i18n.locale"
          @change="updateActiveLocale"
        >
          <v-list-item
            v-for="locale in locales"
            :key="locale.locale"
            :value="locale.locale"
          >
            <v-img
              :src="locale.img"
              height="14px"
              width="22px"
              :alt="locale.locale"
              class="me-2"
            ></v-img>
            <v-list-item-title>{{ locale.title }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import { ref } from '@vue/composition-api'
import { notify } from '@core/dynamicComponents/dynamicComponents'
import { loadLanguageAsync } from '@/plugins/i18n'
import store from '@/store'

export default {
  setup() {
    const { isRtl } = useAppConfig()
    const isSnackbarVisible = ref(false)
    const isSucces = ref(false)
    const message = ref('')

    const locales = [
      {
        title: 'English',
        img: require('@/assets/images/flags/en.png'),
        locale: 'en',
      },
      {
        title: 'Français',
        img: require('@/assets/images/flags/fr.png'),
        locale: 'fr',
      },

      /* {
        title: 'Arabic',
        img: require('@/assets/images/flags/ar.png'),
        locale: 'ar',
      }, */
    ]

    const updateActiveLocale = locale => {
      // Set to RTL mode if locale is arabic
      isRtl.value = locale === 'ar'

      loadLanguageAsync(locale)
      store.dispatch('user/setCurrentUserLang', locale).then(response => {
        message.value = response.message
        isSnackbarVisible.value = true
        isSucces.value = true
        notify(true, true, response.message)
      }).catch(error => {
        message.value = error.response.data.message
        isSnackbarVisible.value = true
      })
    }

    const hideNotification = () => {
      isSnackbarVisible.value = false
    }

    return {
      locales,
      isSucces,
      updateActiveLocale,
      isSnackbarVisible,
      message,
      hideNotification,
    }
  },
}
</script>
