import notificationService from '@/store/services/notificationService'

const state = {
  field: {
    paginate: 10,
    read: null,
    keyword: null,
  },
  paginate_field: null,
  total_notification: null,
  total_all_notification: null,
  metaNotification: null,
  metaAllNotification: null,
  notification: null,
  model: null,
  items: [],
  allItems: [],
}

const getters = {
  notifications: state => (state.metaNotification ? state.metaNotification.data : []),
  all_notifications: state => (state.metaAllNotification ? state.metaAllNotification.data : []),
  current_page: state => (state.metaNotification ? state.metaNotification.current_page : 0),
  current_page_all: state => (state.metaAllNotification ? state.metaAllNotification.current_page : 0),
  last_page: state => (state.metaNotification ? state.metaNotification.last_page : 0),
  last_page_all: state => (state.metaAllNotification ? state.metaAllNotification.last_page : 0),
  per_page: state => (state.metaNotification ? state.metaNotification.per_page : 0),
  per_page_all: state => (state.metaAllNotification ? state.metaAllNotification.per_page : 0),
  total: state => (state.metaNotification ? state.metaNotification.total : 0),
  total_all: state => (state.metaAllNotification ? state.metaAllNotification.total : 0),
}

const actions = {
  // eslint-disable-next-line no-shadow
  getNotificationsList({ commit, state }, { page, field }) {
    return notificationService.getNotifications(page, field).then(({ data }) => {
      commit('SET_META_NOTIFICATION', data.result)
      commit('ADD_ITEMS', data.result)
      commit('SET_TOTAL_NOTIFICATION', data.result.total)
      commit('SET_PAGINATE_FIELD', field.paginate)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  getAllNotificationsList({ commit, state }, { page, field }) {
    return notificationService.getAllNotifications(page, field).then(({ data }) => {
      commit('ADD_ALL_ITEMS', data.result)
      commit('SET_META_ALL_NOTIFICATION', data.result)
      commit('SET_FIELD', field)
      commit('SET_TOTAL_ALL_NOTIFICATION', data.result.total)
      commit('SET_PAGINATE_FIELD', field.paginate)

      return data
    })
  },

  refreshNotificationsList({ commit, state }, { page, field }) {
    return notificationService.getNotifications(page, field).then(({ data }) => {
      commit('SET_META_NOTIFICATION', data.result)
      commit('SET_ITEMS', data.result)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_TOTAL_NOTIFICATION', data.result.total)

      return data
    })
  },

  refreshAllNotificationsList({ commit, state }, { page, field }) {
    return notificationService.getAllNotifications(page, field).then(({ data }) => {
      commit('SET_ALL_ITEMS', data.result)
      commit('SET_FIELD', field)
      commit('SET_META_ALL_NOTIFICATION', data.result)
      commit('SET_TOTAL_ALL_NOTIFICATION', data.result.total)
      commit('SET_PAGINATE_FIELD', field.paginate)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  getNotificationById({ commit, state }, notification) {
    if (state.notification && state.notification.id === notification.id) return state.notification

    return notificationService.getNotification(notification).then(({ data }) => {
      commit('DECRIMENT_TOTAL_NOTIFICATION', notification)
      commit('SET_NOTIFICATION', data.result)
      commit('UPDATE_NOTIFICATION', { notification: data.result, item: notification })
      commit('UPDATE_ALL_NOTIFICATION', { notification: data.result, item: notification })

      return data
    })
  },

  getModelById({ commit, state }, id) {
    return notificationService.getModel(id).then(({ data }) => {
      commit('SET_MODEL', data.result)

      return data
    })
  },
}

const mutations = {
  UPDATE_NOTIFICATION(state, { notification, item }) {
    if (state.metaNotification) { state.metaNotification.data.splice(state.metaNotification.data.findIndex(t => t.id === item.id), 1, notification) }
    if (state.items) { state.items.splice(state.items.findIndex(t => t.id === item.id), 1, notification) }
  },
  // eslint-disable-next-line no-shadow
  SET_NOTIFICATION(state, item) {
    state.notification = item
  },

  SET_MODEL(state, item) {
    state.model = item
  },
  // eslint-disable-next-line no-shadow
  SET_USER_ID_FIELD(state, item) {
    state.user_id_field = item
  },

  // eslint-disable-next-line no-shadow
  SET_AGENT_FIELD(state, item) {
    state.agent_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_PAGINATE_FIELD(state, paginante) {
    state.paginate_field = paginante
  },
  // eslint-disable-next-line no-shadow
  SET_IS_ACTIVE_FIELD(state, is_active) {
    state.is_active_field = is_active
  },
  // eslint-disable-next-line no-shadow
  SET_META_NOTIFICATION(state, metaNotification) {
    state.metaNotification = metaNotification
  },

  // eslint-disable-next-line no-shadow
  SET_META_ALL_NOTIFICATION(state, metaNotification) {
    state.metaAllNotification = metaNotification
  },

  // eslint-disable-next-line no-shadow
  ADD_ITEMS(state, items) {
    state.items.push(...items.data)
  },

  // eslint-disable-next-line no-shadow
  ADD_ALL_ITEMS(state, items) {
    state.allItems.push(...items.data)
  },

  // eslint-disable-next-line no-shadow
  SET_ALL_ITEMS(state, items) {
    state.allItems = items.data
  },

  // eslint-disable-next-line no-shadow
  SET_ITEMS(state, items) {
    state.items = items.data
  },

  UPDATE_ALL_NOTIFICATION(state, { notification, item }) {
    if (state.allItems) { state.allItems.splice(state.allItems.findIndex(t => t.id === item.id), 1, notification) }
  },

  // eslint-disable-next-line no-shadow
  SET_TOTAL_NOTIFICATION(state, total) {
    state.total_notification = total
  },

  // eslint-disable-next-line no-shadow
  SET_TOTAL_ALL_NOTIFICATION(state, total) {
    state.total_all_notification = total
  },

  DECRIMENT_TOTAL_NOTIFICATION(state, item) {
    if (item.read_at === null) state.total_notification -= 1
  },

  // eslint-disable-next-line no-shadow
  SET_FIELD(state, field) {
    state.field = field
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
