import { notify } from '@core/dynamicComponents/dynamicComponents'
import apiService from '@/store/services/apiService'

const state = {
  field: {
    keyword: null,
    paginate: 10,
    is_sandbox: null,
    is_active: null,
    operator_id: null,
  },
  keyword_field: null,
  operator_id_field: null,
  is_active_field: null,
  is_sandbox_field: null,
  paginate_field: null,
  metaApi: null,
  api: null,
}

const getters = {
  apis: state => (state.metaApi ? state.metaApi.data : []),
  current_page: state => (state.metaApi ? state.metaApi.current_page : 0),
  last_page: state => (state.metaApi ? state.metaApi.last_page : 0),
  per_page: state => (state.metaApi ? state.metaApi.per_page : 0),
}

const actions = {
  // eslint-disable-next-line no-shadow
  getApisList({ commit, state }, { page, field }) {
    if (state.metaApi && state.metaApi.current_page === page
      && state.operator_id_field === field.operator_id && state.is_active_field === field.is_active
      && state.is_sandbox_field === field.is_sandbox && state.keyword_field === field.keyword
      && state.paginate_field === field.paginate) return state.metaApi

    return apiService.getApis(page, field).then(({ data }) => {
      commit('SET_META_API', data.result)
      commit('SET_KEYWORD_FIELD', field.keyword)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_OPERATOR_ID_FIELD', field.operator_id)
      commit('SET_IS_ACTIVE_FIELD', field.is_active)
      commit('SET_IS_SANDBOX_FIELD', field.is_sandbox)
      commit('SET_FIELD', field)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  getApiById({ commit, state }, id) {
    return apiService.getApi(id).then(({ data }) => {
      commit('SET_API', data.result)

      return data
    })
  },

  updateApi({ commit, state }, api) {
    return apiService.updateApi(api).then(({ data }) => {
      commit('UPDATE_API', { api: data.result, item: api })
      notify(true, true, data.message)

      return data
    })
  },

  activateApi({ commit, state }, api) {
    return apiService.activateApi(api.id).then(({ data }) => {
      commit('UPDATE_API', { api: data.result, item: api })

      return data
    })
  },

  // eslint-disable-next-line no-unused-vars,no-shadow
  setOpId({ commit, state }, id) {
    commit('SET_OPERATOR_ID', id)
  },
}

const mutations = {
  UPDATE_API(state, { api, item }) {
    if (state.metaApi) { state.metaApi.data.splice(state.metaApi.data.findIndex(t => t.id === item.id), 1, api) }
  },
  // eslint-disable-next-line no-shadow
  SET_API(state, item) {
    state.api = item
  },
  // eslint-disable-next-line no-shadow
  SET_DESTINATION_FIELD(state, item) {
    state.destination_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_USER_ID_FIELD(state, item) {
    state.user_id_field = item
  },

  // eslint-disable-next-line no-shadow
  SET_NB_PASSAGERS_FIELD(state, item) {
    state.nb_passagers_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_START_DATE_FIELD(state, item) {
    state.start_date_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_PAGINATE_FIELD(state, paginante) {
    state.paginate_field = paginante
  },
  // eslint-disable-next-line no-shadow
  SET_KEYWORD_FIELD(state, keyword) {
    state.keyword_field = keyword
  },

  // eslint-disable-next-line no-shadow
  SET_OPERATOR_ID_FIELD(state, item) {
    state.operator_id_field = item
  },

  // eslint-disable-next-line no-shadow
  SET_IS_ACTIVE_FIELD(state, item) {
    state.is_active_field = item
  },

  // eslint-disable-next-line no-shadow
  SET_IS_SANDBOX_FIELD(state, item) {
    state.is_sandbox_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_META_API(state, metaApi) {
    state.metaApi = metaApi
  },

  // eslint-disable-next-line no-shadow
  SET_FIELD(state, field) {
    state.field = field
  },

  // eslint-disable-next-line no-shadow,camelcase
  SET_OPERATOR_ID(state, operator_id) {
    // eslint-disable-next-line camelcase
    state.field.operator_id = operator_id
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
