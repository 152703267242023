import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import {
  getMessaging, getToken, onMessage, deleteToken,
} from 'firebase/messaging'
import { initializeApp } from 'firebase/app'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import { i18n } from '@/plugins/i18n'

const firebaseConfig = {
  apiKey: 'AIzaSyB7IauQIuTGzLWEIuVJQPiHAhOabI9pymc',
  authDomain: 'phonecheck-80669.firebaseapp.com',
  projectId: 'phonecheck-80669',
  storageBucket: 'phonecheck-80669.appspot.com',
  messagingSenderId: '422750725065',
  appId: '1:422750725065:web:7faaf309fcc0146c333e18',
}

const firebaseApp = initializeApp(firebaseConfig)

const messaging = getMessaging(firebaseApp)

console.log(localStorage.getItem('device_token'))

const appUrl = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_URL : process.env.VUE_APP_DEV_URL
if (messaging) {
  navigator.serviceWorker.register(`${appUrl}/firebase-messaging-sw.js`, { type: 'module', scope: '/' }).then(serviceWorkerRegistration => {
    getToken(messaging, {
      serviceWorkerRegistration,
      vapidKey: 'BC-pgGNy5h2kUpz8-bgvQ1IS-xaZV1MneH44EhaHXDJXQAC0mfO2ww_KeNKXP7ei2P6N4JxRzqWO-BowJSZRi1I',
    }).then(currentToken => {
      if (currentToken) {
        // Send the token to your server and update the UI if necessary
        // ...
        localStorage.removeItem('device_token')
        localStorage.setItem('device_token', currentToken)
        console.log(currentToken)
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.')
      }
    }).catch(err => {
      console.log('An error occurred while retrieving token. ', err)
    })
  })
}

onMessage(messaging, payload => {
  console.log('Message received. ', payload)

  const channel = new BroadcastChannel('notification')
  channel.postMessage(payload)
  Notification.requestPermission(result => {
    if (result === 'granted') {
      navigator.serviceWorker.ready.then(registration => {
        registration.showNotification(payload.notification.title, {
          body: payload.notification.body,
          data: payload.data,
          icon: '@/assets/images/svg/mboa_cov_ico.svg',
          actions: [{
            action: 'view',
            title: 'Voir',
          }],
        })
      })
    }
  })
})

Vue.config.productionTip = false

new Vue({
  // firebaseApp,
  messaging,

  // websocket,
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
