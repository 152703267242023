import authService from '@/store/services/authService'
import userService from '@/store/services/userService'
import store from '@/store'
import router from '@/router'

const state = {
  currentUser: {
    name: null,
    lang: null,
    role: {},
    menu: {
      dashboard: false,
      road: false,
      document: false,
      recovery: false,
      payment: false,
      reduct_code: false,
      member: false,
      admin: false,
      role: false,
      setting: false,
      proximity: false,
      recovery_status: false,
      pay_status: false,
      rec_type: false,
      pay_reason: false,
      global_values: false,
      operators: false,
    },
  },
  stat: {
    total_user: null,
    total_driver: null,
    total_member: null,
    total_admin: null,
    total_submitted: null,
    total_unsubmitted: null,
  },
  field: {
    name: null,
    keyword: null,
    paginate: 10,
    role_id: 10,
    license_validated: null,
  },

  fieldAdmin: {
    name: null,
    keyword: null,
    paginate: 10,
    role_id: null,
  },
  name_field: null,
  role_id_field: null,
  keyword_field: null,
  paginate_field: null,
  license_validated_at_field: null,
  name_admin_field: null,
  keyword_admin_field: null,
  paginate_admin_field: null,
  token: null,
  metaMember: null,
  metaAdmin: null,
  user: {
    id: null,
    license_driver_path: null,
    profile_picture_path: null,
    identity: {},
    licence: {},
    role: {
      id: null,
      code: null,
    },
  },
  resp_message: null,
  lock_message: null,
  email: null,
  lang: null,
  isNotifVisible: false,
  isNotifSuccess: false,
  notifMessage: null,
  dashboardAccess: false,
  roadAccess: false,
  paymentAccess: false,
  recoveryAccess: false,
  reductCodeAccess: false,
  memberAccess: false,
  adminAccess: false,
  settingAccess: false,
  roleAccess: false,
  refreshUserId: null,
  refreshUserCount: 0,
  refreshMenuCount: 0,
  refreshMemberCount: 0,
}

const getters = {
  isLoggedIn(state) {
    return !!state.token
  },
  members: state => (state.metaMember ? state.metaMember.data : []),
  current_page: state => (state.metaMember ? state.metaMember.current_page : 0),
  total_member: state => (state.metaMember ? state.metaMember.total : 0),
  last_page: state => (state.metaMember ? state.metaMember.last_page : 0),
  per_page: state => (state.metaMember ? state.metaMember.per_page : 0),
  admins: state => (state.metaAdmin ? state.metaAdmin.data : []),
  current_admin_page: state => (state.metaAdmin ? state.metaAdmin.current_page : 0),
  last_admin_page: state => (state.metaAdmin ? state.metaAdmin.last_page : 0),
  per_admin_page: state => (state.metaAdmin ? state.metaAdmin.per_page : 0),
  total_admin: state => (state.metaAdmin ? state.metaAdmin.total : 0),
  menu: state => (state.currentUser ? state.currentUser.menu : null),
}

const actions = {
  setCurrentUserLang({ commit, state }, lang) {
    return authService.setLang(lang).then(({ data }) => {
      commit('SET_LANG', lang)
      if (data.result) commit('SET_CURRENT_USER', data.result)

      return data
    })
  },

  getStatistics({ commit, state }) {
    return userService.getStats().then(({ data }) => {
      commit('SET_STAT', data.result)

      return data
    })
  },

  login({ commit, state }, field) {
    return authService.login(field).then(({ data }) => {
      commit('SET_CURRENT_USER', data.result.user)
      commit('SET_TOKEN', data.result.access_token)

      return data
    })
  },

  logout({ commit, state }, device) {
    return authService.logout(device).then(({ data }) => {
      commit('REMOVE_TOKEN', null)
      commit('SET_CURRENT_USER', null)
      commit('SET_MESSAGE', data.message)

      return data
    }).catch(({ error }) => {
      console.log(error)

      return error
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  getCurrentUser({ commit, state }, device) {
    return authService.getCurrentUser(device).then(({ data }) => {
      commit('SET_LANG', data.lang)
      commit('SET_CURRENT_USER', data)

      return data
    })
  },

  // eslint-disable-next-line no-shadow
  getMembersList({ commit, state }, { page, field }) {
    if (state.metaMember && state.metaMember.current_page === page
        && state.name_field === field.name && state.paginate_field === field.paginate
      && state.keyword_field === field.keyword && state.license_validated_at_field === field.license_validated) return state.metaMember

    return userService.getMembers(page, field).then(({ data }) => {
      commit('SET_META_MEMBER', data.result)
      commit('SET_NAME_FIELD', field.name)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_KEYWORD_FIELD', field.keyword)
      commit('SET_LICENSE_FIELD', field.license_validated)
      commit('SET_FIELD', field)

      return data
    })
  },

  refreshMembersList({ commit, state }, { page, field }) {
    return userService.getMembers(page, field).then(({ data }) => {
      commit('SET_META_MEMBER', data.result)
      commit('SET_NAME_FIELD', field.name)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_KEYWORD_FIELD', field.keyword)
      commit('SET_LICENSE_FIELD', field.license_validated)
      commit('SET_FIELD', field)

      return data
    })
  },

  // eslint-disable-next-line no-shadow
  getAdminsList({ commit, state }, { page, field }) {
    if (state.metaAdmin && state.metaAdmin.current_page === page
        && state.name_admin_field === field.name && state.paginate_admin_field === field.paginate
      && state.keyword_admin_field === field.keyword && state.role_id_field === field.role_id) return state.metaAdmin

    return userService.getAdmins(page, field).then(({ data }) => {
      commit('SET_META_ADMIN', data.result)
      commit('SET_NAME_ADMIN_FIELD', field.name)
      commit('SET_PAGINATE_ADMIN_FIELD', field.paginate)
      commit('SET_ROLE_FIELD', field.role_id)
      commit('SET_KEYWORD_ADMIN_FIELD', field.keyword)
      commit('SET_FIELD_ADMIN', field)

      return data
    })
  },

  refreshAdminsList({ commit, state }, { page, field }) {
    return userService.getAdmins(page, field).then(({ data }) => {
      commit('SET_META_ADMIN', data.result)
      commit('SET_NAME_ADMIN_FIELD', field.name)
      commit('SET_PAGINATE_ADMIN_FIELD', field.paginate)
      commit('SET_ROLE_FIELD', field.role_id)
      commit('SET_KEYWORD_ADMIN_FIELD', field.keyword)
      commit('SET_FIELD_ADMIN', field)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  updateAdmin({ commit, state }, user) {
    return userService.update(user).then(({ data }) => {
      commit('UPDATE_USER', { user: data.result, item: user })

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  getUser({ commit, state }, id) {
    return userService.getUser(id).then(({ data }) => {
      commit('SET_USER', data.result)
    })
  },

  activateAdminForSubmitLicense({ commit, state }, user) {
    return userService.activateForSubmitLicense(user).then(({ data }) => {
      commit('SET_USER', data.result)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  addAdmin({ commit, state }, user) {
    return userService.addAdmin(user).then(({ data }) => {
      commit('ADD_USER', data.result)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  validateLicenseDrive({ commit, state }, user) {
    return userService.validateUserLicense(user.id, user.doc).then(({ data }) => {
      commit('SET_USER', data.result)
      commit('UPDATE_MEMBER', { user: data.result, item: user })

      return data
    })
  },

  rejectLicenseDriver({ commit, state }, {user, field, doc}) {
    return userService.rejectUserLicense(user.id, { ...field, doc:doc.id }).then(({ data }) => {
      commit('SET_USER', data.result)
      commit('UPDATE_MEMBER', { user: data.result, item: user })

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  lockUser({ commit, state }, { user, field }) {
    return userService.lockUser(user.id, field).then(({ data }) => {
      commit('SET_USER', data.result)
      commit('UPDATE_MEMBER', { user: data.result, item: user })
      commit('SET_LOCK_MESSAGE', data.message)
      store.dispatch('locked/refreshLockedsList', { page: 1, field: store.state.locked.field })

      return data
    })
  },

  // eslint-disable-next-line no-unused-vars,no-shadow
  forgotPassRequest({ commit, state }, email) {
    return authService.forgotPassword(email).then(({ data }) => {
      commit('SET_EMAIL', email)
      commit('SET_MESSAGE', data.message)

      return data
    })
  },

  // eslint-disable-next-line no-unused-vars,no-shadow
  resetPass({ commit, state }, field) {
    return authService.resetPassword(field).then(({ data }) => {
      commit('SET_MESSAGE', data.message)

      return data
    })
  },

  getDashboardAccess({ commit, state }) {
    return authService.hasPrivilege('VIEWDASHBOARD').then(({ data }) => {
      commit('SET_DASHBOARD', data.result)

      return data
    })
  },

  getRoadAccess({ commit, state }) {
    return authService.hasPrivilege('VIEWROADS').then(({ data }) => {
      commit('SET_ROAD_A', data.result)

      return data
    })
  },

  getPaymentAccess({ commit, state }) {
    return authService.hasPrivilege('VIEWPAYS').then(({ data }) => {
      commit('SET_PAYMENT_A', data.result)

      return data
    })
  },

  getRecoveryAccess({ commit, state }) {
    return authService.hasPrivilege('VIEWRECOVERIES').then(({ data }) => {
      commit('SET_RECOVERY_A', data.result)

      return data
    })
  },

  getReductCodeAccess({ commit, state }) {
    return authService.hasPrivilege('VIEWREDUCTCODES').then(({ data }) => {
      commit('SET_REDUCTCODE_A', data.result)

      return data
    })
  },

  getMemberAccess({ commit, state }) {
    return authService.hasPrivilege('VIEWMEMBERS').then(({ data }) => {
      commit('SET_MEMBER_A', data.result)

      return data
    })
  },

  getAdminAccess({ commit, state }) {
    return authService.hasPrivilege('VIEWADMINS').then(({ data }) => {
      commit('SET_ADMIN_A', data.result)

      return data
    })
  },

  hasMenuAccess({ commit, state }, code) {
    return authService.hasPrivilege(code).then(({ data }) => data)
  },

  verifyFcmToken({ commit, state }, token) {
    return authService.verifyFcmToken(token).then(({ data }) => data)
  },

  getRoleAccess({ commit, state }) {
    return authService.hasPrivilege('VIEWROLES').then(({ data }) => {
      console.log(`rest ${data.result}`)
      commit('SET_ROLE_A', data.result)

      return data
    })
  },

  getSettingAccess({ commit, state }) {
    return authService.hasPrivilege('SETTINGS').then(({ data }) => {
      commit('SET_SETTING_A', data.result)

      return data
    })
  },

  redirectToMenu({ commit, state }) {
    if (state.currentUser.menu.dashboard) router.push({ name: 'home' })
    else if (state.currentUser.menu.road) router.push({ name: 'roads' })
    else if (state.currentUser.menu.document) router.push({ name: 'documents' })
    else if (state.currentUser.menu.payment) router.push({ name: 'paiements' })
    else if (state.currentUser.menu.recovery) router.push({ name: 'recoveries' })
    else if (state.currentUser.menu.reduct_code) router.push({ name: 'reduct_codes' })
    else if (state.currentUser.menu.member) router.push({ name: 'members' })
    else if (state.currentUser.menu.admin) router.push({ name: 'admins' })
    else if (state.currentUser.menu.role) router.push({ name: 'roles' })
    else if (state.currentUser.menu.proximity) router.push({ name: 'proximargin' })
    else if (state.currentUser.menu.recovery_status) router.push({ name: 'statuses_rec' })
    else if (state.currentUser.menu.pay_status) router.push({ name: 'statuses' })
    else if (state.currentUser.menu.rec_type) router.push({ name: 'rec_types' })
    else if (state.currentUser.menu.pay_reason) router.push({ name: 'pay_reasons' })
    else if (state.currentUser.menu.setting) router.push({ name: 'setting' })
    else if (state.currentUser.menu.operators) router.push({ name: 'operator' })
    else {
      authService.logout(localStorage.getItem('device_token') !== null ? localStorage.getItem('device_token') : null)
    }
  },
}

const mutations = {
  // eslint-disable-next-line no-shadow
  ADD_USER(state, user) {
    state.metaAdmin.data.unshift(user)
  },

  SET_REFRESH_USER_ID(state, item) {
    state.refreshUserId = item
  },

  INCREMENT_REFRESH_USER_COUNT(state) {
    state.refreshUserCount++
  },

  INCREMENT_REFRESH_MENU_COUNT(state) {
    state.refreshMenuCount++
  },

  INCREMENT_REFRESH_MEMBER_COUNT(state) {
    state.refreshMemberCount++
  },

  // eslint-disable-next-line no-shadow
  SET_EMAIL(state, email) {
    state.email = email
  },

  // eslint-disable-next-line no-shadow
  SET_MESSAGE(state, message) {
    state.resp_message = message
  },

  // eslint-disable-next-line no-shadow
  SET_DASHBOARD(state, item) {
    state.dashboardAccess = item
  },

  // eslint-disable-next-line no-shadow
  SET_ROAD_A(state, item) {
    state.roadAccess = item
  },

  // eslint-disable-next-line no-shadow
  SET_PAYMENT_A(state, item) {
    state.paymentAccess = item
  },

  // eslint-disable-next-line no-shadow
  SET_RECOVERY_A(state, item) {
    state.recoveryAccess = item
  },

  // eslint-disable-next-line no-shadow
  SET_REDUCTCODE_A(state, item) {
    state.reductCodeAccess = item
  },

  // eslint-disable-next-line no-shadow
  SET_MEMBER_A(state, item) {
    state.memberAccess = item
  },

  // eslint-disable-next-line no-shadow
  SET_ADMIN_A(state, item) {
    state.adminAccess = item
  },

  // eslint-disable-next-line no-shadow
  SET_ROLE_A(state, item) {
    state.roleAccess = item
  },

  // eslint-disable-next-line no-shadow
  SET_SETTING_A(state, item) {
    state.settingAccess = item
  },

  // eslint-disable-next-line no-shadow
  SET_LOCK_MESSAGE(state, message) {
    state.lock_message = message
  },

  // eslint-disable-next-line no-shadow
  SET_USER(state, user) {
    state.user = user
  },

  // eslint-disable-next-line no-shadow
  SET_CURRENT_USER(state, currentUser) {
    state.currentUser = currentUser
    localStorage.setItem('admin', currentUser.menu.admin)
    localStorage.setItem('member', currentUser.menu.member)
    localStorage.setItem('dashboard', currentUser.menu.dashboard)
    localStorage.setItem('road', currentUser.menu.road)
    localStorage.setItem('document', currentUser.menu.document)
    localStorage.setItem('payment', currentUser.menu.payment)
    localStorage.setItem('recovery', currentUser.menu.recovery)
    localStorage.setItem('reduct_code', currentUser.menu.reduct_code)
    localStorage.setItem('role', currentUser.menu.role)
    localStorage.setItem('setting', currentUser.menu.setting)
  },

  // eslint-disable-next-line no-shadow
  SET_LANG(state, item) {
    state.lang = item
    localStorage.setItem('lang', item)
  },

  // eslint-disable-next-line no-shadow
  SET_STAT(state, stat) {
    state.stat = stat
  },
  // eslint-disable-next-line no-shadow
  SET_TOKEN(state, token) {
    state.token = token
    localStorage.setItem('accessToken', token)
  },

  // eslint-disable-next-line no-shadow
  REMOVE_TOKEN(state, token) {
    state.token = token
    localStorage.removeItem('accessToken')
    localStorage.removeItem('admin')
    localStorage.removeItem('dashboard')
    localStorage.removeItem('member')
    localStorage.removeItem('road')
    localStorage.removeItem('payment')
    localStorage.removeItem('recovery')
    localStorage.removeItem('reduct_code')
    localStorage.removeItem('role')
    localStorage.removeItem('setting')
    localStorage.removeItem('device_token')
  },
  // eslint-disable-next-line no-shadow
  SET_META_MEMBER(state, metaMember) {
    state.metaMember = metaMember
  },
  // eslint-disable-next-line no-shadow
  SET_META_ADMIN(state, metaAdmin) {
    state.metaAdmin = metaAdmin
  },
  // eslint-disable-next-line camelcase,no-shadow
  SET_NAME_FIELD(state, name_field) {
    // eslint-disable-next-line camelcase
    state.name_field = name_field
  },

  // eslint-disable-next-line no-shadow,camelcase
  SET_KEYWORD_FIELD(state, keyword_field) {
    // eslint-disable-next-line camelcase
    state.keyword_field = keyword_field
  },

  // eslint-disable-next-line camelcase,no-shadow
  SET_LICENSE_FIELD(state, license_field) {
    // eslint-disable-next-line camelcase
    state.license_validated_at_field = license_field
  },

  // eslint-disable-next-line camelcase,no-shadow
  SET_PAGINATE_FIELD(state, paginate_field) {
    // eslint-disable-next-line camelcase
    state.paginate_field = paginate_field
  },

  // eslint-disable-next-line no-shadow,camelcase
  SET_NAME_ADMIN_FIELD(state, name_admin_field) {
    // eslint-disable-next-line camelcase
    state.name_admin_field = name_admin_field
  },

  // eslint-disable-next-line camelcase,no-shadow
  SET_KEYWORD_ADMIN_FIELD(state, keyword_admin_field) {
    // eslint-disable-next-line camelcase
    state.keyword_admin_field = keyword_admin_field
  },

  // eslint-disable-next-line no-shadow,camelcase
  SET_PAGINATE_ADMIN_FIELD(state, paginate_admin_field) {
    // eslint-disable-next-line camelcase
    state.paginate_admin_field = paginate_admin_field
  },
  // eslint-disable-next-line no-shadow,camelcase
  SET_ROLE_FIELD(state, item) {
    // eslint-disable-next-line camelcase
    state.role_id_field = item
  },

  // eslint-disable-next-line no-shadow
  SET_FIELD(state, field) {
    state.field = field
  },

  // eslint-disable-next-line no-shadow
  SET_FIELD_ADMIN(state, fieldAdmin) {
    state.fieldAdmin = fieldAdmin
  },

  // eslint-disable-next-line no-shadow
  UPDATE_USER(state, { user, item }) {
    if (state.metaAdmin) { state.metaAdmin.data.splice(state.metaAdmin.data.findIndex(t => t.id === item.id), 1, user) }
  },

  // eslint-disable-next-line no-shadow
  UPDATE_MEMBER(state, { user, item }) {
    if (state.metaMember) { state.metaMember.data.splice(state.metaMember.data.findIndex(t => t.id === item.id), 1, user) }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
