const configPages = [
  {
    path: '/settings',
    name: 'setting',
    component: () => import('@/views/setting/Setting.vue'),
    meta: {
      layout: 'content',
      title: "Valeurs globales",
    },
  },
]

export default configPages
