const configPages = [
  {
    path: '/proximargins',
    name: 'proximargin',
    component: () => import('@/views/proximargin/ProxiMargin.vue'),
    meta: {
      layout: 'content',
      title: "Marge de proximité",
    },
  },
]

export default configPages
