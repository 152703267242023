import axios from '@axios'

export default {
  getMembers(page, field) {
    return axios.post(`admin/users/members?page=${page}`, field)
  },

  getAdmins(page, field) {
    return axios.post(`admin/users/admins?page=${page}`, field)
  },

  update(user) {
    return axios.put(`admin/users/${user.id}`, user)
  },

  getUser(id) {
    return axios.get(`admin/users/${id}`)
  },

  validateUserLicense(id,doc) {
    return axios.post(`admin/users/validate/${id}/license`, {doc: doc})
  },

  rejectUserLicense(id, field, doc) {
    return axios.put(`admin/users/reject/${id}/license`, field)
  },

  lockUser(id, field) {
    return axios.put(`admin/users/${id}/lock`, field)
  },

  getStats() {
    return axios.get('admin/users/stats/get')
  },

  addAdmin(user) {
    return axios.post('admin/users', user)
  },

  activateForSubmitLicense(user) {
    return axios.get(`admin/users/activate/${user.id}/license/notification`, user)
  },
}
