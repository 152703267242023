import { notify } from '@core/dynamicComponents/dynamicComponents'
import apiTokenService from '@/store/services/apiTokenService'

const state = {
  field: {
    is_active: null,
    paginate: 10,
    api_id: null,
    operator_id: null,
  },
  is_active_field: null,
  operator_id_field: null,
  api_id_field: null,
  paginate_field: null,
  metaApiToken: null,
  apiToken: {
    id: null,
    value: null,
    api_key: null,
    type: null,
    reference: null,
    expires_at: null,
    api_id: null,
    api_product_id: null,
    provider_cb: null,
    target_env: null,
    is_active: null,
  },
}

const getters = {
  apiTokens: state => (state.metaApiToken ? state.metaApiToken.data : []),
  current_page: state => (state.metaApiToken ? state.metaApiToken.current_page : 0),
  last_page: state => (state.metaApiToken ? state.metaApiToken.last_page : 0),
  per_page: state => (state.metaApiToken ? state.metaApiToken.per_page : 0),
}

const actions = {
  // eslint-disable-next-line no-shadow
  getApiTokensList({ commit, state }, { page, field }) {
    if (state.metaApiToken && state.metaApiToken.current_page === page
      && state.operator_id_field === field.operator_id && state.api_id_field === field.api_id
      && state.is_active_field === field.is_active && state.paginate_field === field.paginate) return state.metaApiToken

    return apiTokenService.getApiTokens(page, field).then(({ data }) => {
      commit('SET_META_API_TOKEN', data.result)
      commit('SET_IS_ACTIVE_FIELD', field.is_active)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_OPERATOR_ID_FIELD', field.operator_id)
      commit('SET_API_ID_FIELD', field.api_id)
      commit('SET_FIELD', field)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  getApiTokenById({ commit, state }, id) {
    return apiTokenService.getApiToken(id).then(({ data }) => {
      commit('SET_API_TOKEN', data.result)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  viewKey({ commit, state }, id) {
    return apiTokenService.viewTokenKeys(id).then(({ data }) => {
      commit('SET_API_TOKEN', data.result)

      return data
    })
  },

  hideKey({ commit, state }, id) {
    return apiTokenService.hideTokenKeys(id).then(({ data }) => data)
  },

  // eslint-disable-next-line no-unused-vars,no-shadow
  updateKey({ commit, state }, apiToken) {
    return apiTokenService.updateTokenKeys(apiToken).then(({ data }) => {
      notify(true, true, data.message)

      return data
    })
  },

}

const mutations = {
  UPDATE_API_PRODUCT(state, { api, item }) {
    if (state.metaApiToken) { state.metaApiToken.data.splice(state.metaApiToken.data.findIndex(t => t.id === item.id), 1, api) }
  },
  // eslint-disable-next-line no-shadow
  SET_API_TOKEN(state, item) {
    state.apiToken = item
  },
  // eslint-disable-next-line no-shadow
  SET_PAGINATE_FIELD(state, paginante) {
    state.paginate_field = paginante
  },
  // eslint-disable-next-line no-shadow
  SET_IS_ACTIVE_FIELD(state, is_active) {
    state.is_active_field = is_active
  },

  // eslint-disable-next-line no-shadow
  SET_OPERATOR_ID_FIELD(state, item) {
    state.operator_id_field = item
  },

  // eslint-disable-next-line no-shadow
  SET_API_ID_FIELD(state, item) {
    state.api_id_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_META_API_TOKEN(state, metaApiToken) {
    state.metaApiToken = metaApiToken
  },

  // eslint-disable-next-line no-shadow
  SET_FIELD(state, field) {
    state.field = field
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
