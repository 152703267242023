import axios from '@axios'

export default {
  getNotifications(page, field) {
    return axios.post(`platform/notifications/list?page=${page}`, field)
  },

  getAllNotifications(page, field) {
    return axios.post(`platform/notifications/all?page=${page}`, field)
  },

  getNotification(notification) {
    return axios.get(`platform/notifications/${notification.id}`)
  },

  getModel(id) {
    return axios.get(`platform/notifications/model/${id}/show`)
  },
}
