import axios from '@axios'

export default {
  getApiProducts(page, field) {
    return axios.post(`admin/api_products/list?page=${page}`, field)
  },

  getApiProduct(id) {
    return axios.get(`admin/api_products/${id}`)
  },

  viewProductKeys(id) {
    return axios.get(`admin/api_products/keys/${id}`)
  },

  hideProductKeys(id) {
    return axios.get(`admin/api_products/hidekeys/${id}`)
  },

  updateProductKeys(apiProduct) {
    return axios.put(`admin/api_products/updatekeys/${apiProduct.id}`, apiProduct)
  },

  updateApiProduct(apiProduct) {
    return axios.put(`admin/api_products/${apiProduct.id}`, apiProduct)
  },
}
