import axios from '@axios'

export default {
  getRecTypeList(page, field) {
    return axios.post(`admin/rec_types/list?page=${page}`, field)
  },

  getRecType(id) {
    return axios.get(`admin/rec_types/${id}`)
  },

  updateRecType(field) {
    return axios.put(`admin/rec_types/${field.id}`, field)
  },
}
