import { notify } from '@core/dynamicComponents/dynamicComponents'
import settingService from '@/store/services/settingService'

const state = {
  field: {
    keyword: null,
    paginate: 10,
    setting_type_id: null,
  },
  keyword_field: null,
  paginate_field: null,
  metaSetting: null,
  setting_type_id_field: null,
  setting: {
    id: null,
    setting_type: {
      id: null,
      code: null,
    },
  },
}

const getters = {
  settings: state => (state.metaSetting ? state.metaSetting.data : []),
  current_page: state => (state.metaSetting ? state.metaSetting.current_page : 0),
  last_page: state => (state.metaSetting ? state.metaSetting.last_page : 0),
  per_page: state => (state.metaSetting ? state.metaSetting.per_page : 0),
  total: state => (state.metaSetting ? state.metaSetting.total : 0),
}

const actions = {
  // eslint-disable-next-line no-shadow
  getSettingList({ commit, state }, { page, field }) {
    if (state.metaSetting && state.metaSetting.current_page === page
      && state.keyword_field === field.keyword
      && state.paginate_field === field.paginate && state.setting_type_id_field === field.setting_type_id) return state.metaSetting

    return settingService.getSettings(page, field).then(({ data }) => {
      commit('SET_META_SETTING', data.result)
      commit('SET_KEYWORD_FIELD', field.keyword)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_SETTING_TYPE_ID_FIELD', field.setting_type_id)

      return data
    })
  },

  refreshSettingList({ commit, state }, { page, field }) {
    return settingService.getSettings(page, field).then(({ data }) => {
      commit('SET_META_SETTING', data.result)
      commit('SET_KEYWORD_FIELD', field.keyword)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_SETTING_TYPE_ID_FIELD', field.setting_type_id)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  getSettingById({ commit, state }, id) {
    return settingService.getSetting(id).then(({ data }) => {
      commit('SET_SETTING', data.result)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  updateSetting({ commit, state }, setting) {
    return settingService.updateSetting(setting).then(({ data }) => {
      commit('UPDATE_SETTING', { setting: data.result, item: setting })
      notify(true, true, data.message)
    })
  },
}

const mutations = {
  // eslint-disable-next-line no-shadow
  UPDATE_SETTING(state, { setting, item }) {
    if (state.metaSetting) { state.metaSetting.data.splice(state.metaSetting.data.findIndex(t => t.id === item.id), 1, setting) }
  },
  // eslint-disable-next-line no-shadow
  SET_SETTING(state, item) {
    state.setting = item
  },

  // eslint-disable-next-line no-shadow
  SET_PAGINATE_FIELD(state, paginante) {
    state.paginate_field = paginante
  },
  // eslint-disable-next-line no-shadow
  SET_KEYWORD_FIELD(state, keyword) {
    state.keyword_field = keyword
  },
  // eslint-disable-next-line no-shadow
  SET_META_SETTING(state, metaSetting) {
    state.metaSetting = metaSetting
  },

  // eslint-disable-next-line no-shadow
  SET_FIELD(state, field) {
    state.field = field
  },

  // eslint-disable-next-line no-shadow
  SET_SETTING_TYPE_ID_FIELD(state, field) {
    state.setting_type_id_field = field
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
