import axios from '@axios'

export default {
  getDestinations(page, field) {
    return axios.post(`platform/destinations/list?page=${page}`, field)
  },

  getDestination(id) {
    return axios.get(`platform/destinations/${id}`)
  },
}
