import axios from '@axios'

export default {
  getStatusesRecoveryList(page, field) {
    return axios.post(`admin/statuses_recs/list?page=${page}`, field)
  },

  getStatusRecovery(id) {
    return axios.get(`admin/statuses_recs/${id}`)
  },

  addStatusRecovery(field) {
    return axios.post('admin/statuses_recs', field)
  },

  updateStatusRecovery(field) {
    return axios.put(`admin/statuses_recs/${field.id}`, field)
  },

  deleteStatusRecovery(field) {
    return axios.delete(`admin/statuses_recs/${field.id}`, field)
  },

  restoreStatusRecovery(field) {
    return axios.post(`admin/statuses_recs/${field.id}/restore`)
  },
}
