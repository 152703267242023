import axios from '@axios'

export default {
  getDevices(page, field) {
    return axios.post(`platform/devices/list?page=${page}`, field)
  },

  getDevice(id) {
    return axios.get(`platform/devices/${id}`)
  },

  deleteDevice(id) {
    return axios.post(`platform/devices/delete/${id}`)
  },
}
