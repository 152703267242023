import campaignService from '@/store/services/campaignService'

const state = {
  field: {
    is_percent: null,
    end_date: null,
    quantity_per_user: null,
    init_quantity: null,
    user: null,
    user_id: null,
    value: null,
    label: null,
    code: null,
    paginate: 10,
  },
  code_field: null,
  label_field: null,
  value_field: null,
  is_percent_field: null,
  user_field: null,
  user_id_field: null,
  init_quantity_field: null,
  quantity_per_user_field: null,
  end_date_field: null,
  paginate_field: null,
  metaCampaign: null,
  campaign: null,
}

const getters = {
  campaigns: state => (state.metaCampaign ? state.metaCampaign.data : []),
  current_page: state => (state.metaCampaign ? state.metaCampaign.current_page : 0),
  last_page: state => (state.metaCampaign ? state.metaCampaign.last_page : 0),
  per_page: state => (state.metaCampaign ? state.metaCampaign.per_page : 0),
  total: state => (state.metaCampaign ? state.metaCampaign.total : 0),
}

const actions = {
  // eslint-disable-next-line no-shadow
  getCampaignsList({ commit, state }, { page, field }) {
    if (state.metaCampaign && state.metaCampaign.current_page === page
      && state.code_field === field.code && state.paginate_field === field.paginate
      && state.value_field === field.value && state.is_percent_field === field.is_percent
      && state.user_field === field.user && state.init_quantity_field === field.init_quantity
      && state.quantity_per_user_field === field.quantity_per_user && state.end_date_field === field.end_date
      && state.user_id_field === field.user_id && state.label_field === field.label) return state.metaCampaign

    return campaignService.getCampaignsList(page, field).then(({ data }) => {
      commit('SET_META_CAMPAIGN', data.result)
      commit('SET_CODE_FIELD', field.code)
      commit('SET_LABEL_FIELD', field.label)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_VALUE_FIELD', field.value)
      commit('SET_IS_PERCENT_FIELD', field.is_percent)
      commit('SET_USER_FIELD', field.user)
      commit('SET_INIT_QUANTITY_FIELD', field.init_quantity)
      commit('SET_INIT_QUANTITY_PER_USER_FIELD', field.quantity_per_user)
      commit('SET_END_DATE_FIELD', field.end_date)
      commit('SET_USER_ID_FIELD', field.user_id)
      commit('SET_FIELD', field)

      return data
    })
  },

  refreshCampaignsList({ commit, state }, { page, field }) {
    return campaignService.getCampaignsList(page, field).then(({ data }) => {
      commit('SET_META_CAMPAIGN', data.result)
      commit('SET_CODE_FIELD', field.code)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_VALUE_FIELD', field.value)
      commit('SET_IS_PERCENT_FIELD', field.is_percent)
      commit('SET_USER_FIELD', field.user)
      commit('SET_INIT_QUANTITY_FIELD', field.init_quantity)
      commit('SET_INIT_QUANTITY_PER_USER_FIELD', field.quantity_per_user)
      commit('SET_END_DATE_FIELD', field.end_date)
      commit('SET_USER_ID_FIELD', field.user_id)
      commit('SET_FIELD', field)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  getCampaignById({ commit, state }, id) {
    return campaignService.getCampaign(id).then(({ data }) => {
      commit('SET_CAMPAIGN', data.result)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  updateCampaign({ commit, state }, field) {
    return campaignService.updateCampaign(field).then(({ data }) => {
      commit('UPDATE_CAMPAIGN', { campaign: data.result, item: field })

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  addCampaign({ commit, state }, field) {
    return campaignService.addCampaign(field).then(({ data }) => {
      commit('ADD_CAMPAIGN', data.result)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  deleteCampaign({ commit, state }, field) {
    return campaignService.deleteCampaign(field).then(({ data }) => {
      commit('UPDATE_CAMPAIGN', { campaign: data.result, item: field })

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  restoreCampaign({ commit, state }, field) {
    return campaignService.restoreCampaign(field).then(({ data }) => {
      commit('UPDATE_CAMPAIGN', { campaign: data.result, item: field })

      return data
    })
  },

}

const mutations = {

  // eslint-disable-next-line no-shadow
  UPDATE_CAMPAIGN(state, { campaign, item }) {
    if (state.metaCampaign) { state.metaCampaign.data.splice(state.metaCampaign.data.findIndex(t => t.id === item.id), 1, campaign) }
    state.campaign = {...campaign, users_ids: campaign.usersids?campaign.usersids.split(','):[]}
  },

  // eslint-disable-next-line no-shadow
  ADD_CAMPAIGN(state, campaign) {
    state.metaCampaign.data.unshift(campaign)
  },

  // eslint-disable-next-line no-shadow
  SET_CAMPAIGN(state, item) {
    state.campaign = {...item, users_ids: item.usersids?item.usersids.split(','):[]}
  },
  // eslint-disable-next-line no-shadow
  SET_OSM_ROUTE(state, item) {
    state.osmRoute = item
  },
  // eslint-disable-next-line no-shadow
  SET_DESTINATION_FIELD(state, item) {
    state.destination_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_CAR_FIELD(state, item) {
    state.car_field = item
  },

  // eslint-disable-next-line no-shadow
  SET_MAX_AMOUNT_FIELD(state, item) {
    state.max_code_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_MIN_AMOUNT_FIELD(state, item) {
    state.admin_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_PAYMENT_ID_FIELD(state, item) {
    state.payment_id_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_USER_FIELD(state, item) {
    state.user_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_PAGINATE_FIELD(state, paginante) {
    state.paginate_field = paginante
  },

  // eslint-disable-next-line no-shadow
  SET_VALUE_FIELD(state, value) {
    state.value_field = value
  },

  // eslint-disable-next-line no-shadow
  SET_IS_PERCENT_FIELD(state, value) {
    state.is_percent_field = value
  },

  // eslint-disable-next-line no-shadow
  SET_INIT_QUANTITY_FIELD(state, value) {
    state.init_quantity_field = value
  },

  // eslint-disable-next-line no-shadow
  SET_INIT_QUANTITY_PER_USER_FIELD(state, value) {
    state.init_quantity_per_user_field = value
  },
  // eslint-disable-next-line no-shadow
  SET_END_DATE_FIELD(state, value) {
    state.end_date_field = value
  },

  // eslint-disable-next-line no-shadow
  SET_USER_ID_FIELD(state, value) {
    state.user_id_field = value
  },
  // eslint-disable-next-line no-shadow
  SET_CODE_FIELD(state, code) {
    state.code_field = code
  },

  // eslint-disable-next-line no-shadow
  SET_LABEL_FIELD(state, item) {
    state.label_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_META_CAMPAIGN(state, metaCampaign) {
    state.metaCampaign = {
      ...metaCampaign,
      data: metaCampaign.data.map(e => ({ ...e, users_ids: e.usersids ? e.usersids.split(',') : [] })),
    }
  },

  // eslint-disable-next-line no-shadow
  SET_FIELD(state, field) {
    state.field = field
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
