import alertService from '@/store/services/alertService'

const state = {
  field: {
    city: null,
    nb_passagers: null,
    start_date: null,
    user_id: null,
    paginate: 10,
    destination: null,
  },
  city_field: null,
  start_date_field: null,
  nb_passagers_field: null,
  user_id_field: null,
  paginate_field: null,
  destination_field: null,
  metaAlert: null,
  alert: null,
}

const getters = {
  alerts: state => (state.metaAlert ? state.metaAlert.data : []),
  current_page: state => (state.metaAlert ? state.metaAlert.current_page : 0),
  last_page: state => (state.metaAlert ? state.metaAlert.last_page : 0),
  per_page: state => (state.metaAlert ? state.metaAlert.per_page : 0),
}

const actions = {
  // eslint-disable-next-line no-shadow
  getAlertsList({ commit, state }, { page, field }) {
    if (state.metaAlert && state.metaAlert.current_page === page
      && state.city_field === field.city && state.paginate_field === field.paginate
      && state.start_date_field === field.start_date && state.nb_passagers_field === field.nb_passagers
      && state.user_id_field === field.user_id && state.car_field === field.car
      && state.destination_field === field.destination) return state.metaAlert

    return alertService.getAlerts(page, field).then(({ data }) => {
      commit('SET_META_ALERT', data.result)
      commit('SET_CITY_FIELD', field.city)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_START_DATE_FIELD', field.start_date)
      commit('SET_NB_PASSAGERS_FIELD', field.nb_passagers)
      commit('SET_FIELD', field)
      commit('SET_USER_ID_FIELD', field.user_id)
      commit('SET_DESTINATION_FIELD', field.destination)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  getAlertById({ commit, state }, id) {
    return alertService.getAlert(id).then(({ data }) => {
      commit('SET_ALERT', data.result)

      return data
    })
  },
}

const mutations = {
  // eslint-disable-next-line no-shadow
  SET_ALERT(state, item) {
    state.alert = item
  },
  // eslint-disable-next-line no-shadow
  SET_DESTINATION_FIELD(state, item) {
    state.destination_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_USER_ID_FIELD(state, item) {
    state.user_id_field = item
  },

  // eslint-disable-next-line no-shadow
  SET_NB_PASSAGERS_FIELD(state, item) {
    state.nb_passagers_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_START_DATE_FIELD(state, item) {
    state.start_date_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_PAGINATE_FIELD(state, paginante) {
    state.paginate_field = paginante
  },
  // eslint-disable-next-line no-shadow
  SET_CITY_FIELD(state, city) {
    state.city_field = city
  },
  // eslint-disable-next-line no-shadow
  SET_META_ALERT(state, metaAlert) {
    state.metaAlert = metaAlert
  },

  // eslint-disable-next-line no-shadow
  SET_FIELD(state, field) {
    state.field = field
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
