import documentService from '@/store/services/documentService'
import opApiService from '@/store/services/opApiService'
import documentTypeService from '@/store/services/documentTypeService'
import store from '@/store'
import recoveryService from '@/store/services/recoveryService'

const state = {
  field: {
    type: null,
    image_1: null,
    image_2: null,
    image_3: null,
    image_4: null,
    user_id: null,
    processed_at: null,
    paginate: 10,
  },
  filterStat: {
    min_date: null,
    max_date: null,
  },
  filterWithdrawValueStat: {
    min_date: null,
    max_date: null,
    is_cashin: false,
  },
  filterDepositValueStat: {
    min_date: null,
    max_date: null,
    is_cashin: true,
  },
  filterWithdraw: {
    min_date: null,
    max_date: null,
  },
  filterReason: {
    min_date: null,
    max_date: null,
  },
  filterValidity: {
    min_date: null,
    max_date: null,
    refresh: 0,
  },
  base_amount_field: null,
  amount_field: null,
  user_field: null,
  admin_field: null,
  user_id_field: null,
  op_api_id_field: null,
  paginate_field: null,
  svc_number_field: null,
  road_id_field: null,
  type_field: null,
  reference_field: null,
  op_amount_field: null,
  destination_id_field: null,
  transaction_id_field: null,
  metaDocument: null,
  metaStats: {
    FAIL: null,
    PENDING: null,
    SUCCESSFUL: null,
    WARNING: null,
  },
  metaReasonStat: null,
  metaValidityStat: null,
  metaWithdrawStats: {
    FAIL: null,
    PENDING: null,
    SUCCESSFUL: null,
    WARNING: null,
  },
  metaWithdrawValueStats: {
    FAIL: null,
    PENDING: null,
    SUCCESSFUL: null,
    WARNING: null,
  },
  metaDepositValueStats: {
    FAIL: null,
    PENDING: null,
    SUCCESSFUL: null,
    WARNING: null,
  },
  catX: [],
  dataY: [],
  catWithdrawX: [],
  dataWithdrawY: [],
  catWithdrawValueX: [],
  dataWithdrawValueY: [],
  dataWithdrawValueColor: [],
  dataWithdrawValuePercent: [],
  catDepositValueX: [],
  dataDepositValueY: [],
  dataDepositValueColor: [],
  dataDepositValuePercent: [],
  catReasonValueX: [],
  dataReasonValueY: [],
  dataReasonValueColor: [],
  dataReasonValuePercent: [],
  catValidityValueX: [],
  dataValidityValueY: [],
  dataValidityValueColor: [],
  dataValidityValuePercent: [],
  metaOpApi: null,
  metaDocumentType: {
    data: [
      { label: 'Permis', id: 'licence' },
      { label: 'CNI', id: 'id_card' },
    ],
  },
  document: {
    id: null,
    type: null,
    user: {},
    admin: {},
  },
  metaGlobalStat: null,
  globalStatKeys: [],
  globalStatValue: [],
  refreshPayId: null,
  refreshPayCount: 0,
}

const getters = {
  opApis: state => (state.metaOpApi ? state.metaOpApi.data : []),
  documentTypes: state => (state.metaDocumentType ? state.metaDocumentType.data : []),
  documents: state => (state.metaDocument ? state.metaDocument.data : []),
  current_page: state => (state.metaDocument ? state.metaDocument.current_page : 0),
  last_page: state => (state.metaDocument ? state.metaDocument.last_page : 0),
  per_page: state => (state.metaDocument ? state.metaDocument.per_page : 0),
  total: state => (state.metaDocument ? state.metaDocument.total : 0),
}

const actions = {
  getdocumentTypes({ commit }) {
    return documentTypeService.getDocumentTypes(1, { paginate: 100 }).then(({ data }) => {
      commit('SET_META_DOCUMENT_TYPE', data.result)

      /* const items = []
      data.result.forEach(elt => {
        items.push({ text: elt.operator.label, value: elt.id })
      })
      commit('SET_META_DOCUMENT_TYPE', items) */

      return data
    })
  },

  getOpApis({ commit }) {
    return opApiService.getOpApis(1, { paginate: 100 }).then(({ data }) => {
      commit('SET_META_OP_API', data.result)

      return data
    })
  },

  getPayChartData({ commit, state }, filter) {
    return documentService.getStatByStatus(filter).then(({ data }) => {
      commit('SET_FILTER', filter)
      commit('SET_META_STATS', data.result)

      return data
    })
  },

  getWithdrawChartData({ commit, state }, filter) {
    return documentService.getWithdrawByStatus(filter).then(({ data }) => {
      commit('SET_FILTER_WITHDRAW', filter)
      commit('SET_META_WITHDRAW_STATS', data.result)

      return data
    })
  },

  getWithdrawValueChartData({ commit, state }, filter) {
    return documentService.getValueStatByStatus(filter).then(({ data }) => {
      commit('SET_FILTER_WITHDRAW_VALUE', filter)
      commit('SET_META_WITHDRAW_VALUE_STATS', data.result)

      return data
    })
  },

  getDepositValueChartData({ commit, state }, filter) {
    return documentService.getValueStatByStatus(filter).then(({ data }) => {
      commit('SET_FILTER_DEPOSIT_VALUE', filter)
      commit('SET_META_DEPOSIT_VALUE_STATS', data.result)

      return data
    })
  },

  getReasonValueChartData({ commit, state }, filter) {
    return documentService.getValueStatByReason(filter).then(({ data }) => {
      commit('SET_FILTER_REASON_VALUE', filter)
      commit('SET_META_REASON_VALUE_STATS', data.result)

      return data
    })
  },

  getValidityValueChartData({ commit, state }, filter) {
    return documentService.getValueStatByValidity(filter).then(({ data }) => {
      commit('SET_FILTER_VALIDITY_VALUE', filter)
      commit('SET_META_VALIDITY_VALUE_STATS', data.result)

      return data
    })
  },

  getGlobalStatData({ commit, state }) {
    return documentService.getGlobalStat().then(({ data }) => {
      commit('SET_META_GLOBAL_STATS', data.result)

      return data
    })
  },

  // eslint-disable-next-line no-shadow
  getDocumentsList({ commit, state }, { page, field }) {
    if (state.metaDocument && state.metaDocument.current_page === page
      && state.user_field === field.user
      && state.admin_field === field.admin
      && state.user_id_field === field.user_id) return state.metaDocument

    return documentService.getDocuments(page, field).then(({ data }) => {
      commit('SET_META_DOCUMENT', data.result)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_TYPE_FIELD', field.type)
      commit('SET_FIELD', field)
      commit('SET_USER_ID_FIELD', field.user_id)

      return data
    })
  },

  refeshDocumentsList({ commit, state }, { page, field }) {
    return documentService.getDocuments(page, field).then(({ data }) => {
      commit('SET_META_DOCUMENT', data.result)
      commit('SET_TYPE_FIELD', field.type)
      commit('SET_REFERENCE_FIELD', field.id)
      commit('SET_FIELD', field)
      commit('SET_USER_ID_FIELD', field.user_id)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  getDocumentById({ commit, state }, id) {
    return documentService.getDocument(id).then(({ data }) => {
      commit('SET_DOCUMENT', data.result)

      return data
    })
  },
  verifyDocumentStatus({ commit, state }, document) {
    return documentService.verifyStatus(document.id).then(({ data }) => {
      commit('UPDATE_DOCUMENT', { document: data.result, item: document })

      return data
    })
  },

  // eslint-disable-next-line no-shadow
  newDocumentEvent({ commit, state }, id) {
    return documentService.getDocument(id).then(({ data }) => {
      commit('ADD_DOCUMENT', data.result)

      return data
    })
  },
}

const mutations = {
  SET_REFRESH_PAY_ID(state, item) {
    state.refreshPayId = item
  },

  INCREMENT_REFRESH_PAY_COUNT(state) {
    state.refreshPayCount++
  },

  // eslint-disable-next-line no-shadow
  SET_DOCUMENT(state, item) {
    state.document = item
  },

  // eslint-disable-next-line no-shadow
  UPDATE_DOCUMENT(state, { document, item }) {
    if (state.metaDocument) { state.metaDocument.data.splice(state.metaDocument.data.findIndex(t => t.id === item.id), 1, document) }
  },

  // eslint-disable-next-line no-shadow
  ADD_DOCUMENT(state, document) {
    if (state.metaDocument) {
      if (state.metaDocument.per_page === state.field.paginate) state.metaDocument.data.pop()
      state.metaDocument.data.unshift(document)
    } else {
      store.dispatch('document/getDocumentsList', { page: 1, field: state.field }).catch(err => {
        console.log(err)
      })
    }
  },
  // eslint-disable-next-line no-shadow
  SET_USER_ID_FIELD(state, item) {
    state.user_id_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_SVC_NUMBER_FIELD(state, item) {
    state.svc_number_field = item
  },

  // eslint-disable-next-line no-shadow
  SET_ROAD_ID_FIELD(state, item) {
    state.road_id_field = item
  },

  // eslint-disable-next-line no-shadow
  SET_TRANSACTION_ID_FIELD(state, item) {
    state.transaction_id_field = item
  },

  // eslint-disable-next-line no-shadow
  SET_TYPE_FIELD(state, item) {
    state.type_field = item
  },

  // eslint-disable-next-line no-shadow
  SET_REFERENCE_FIELD(state, item) {
    state.reference_field = item
  },

  // eslint-disable-next-line no-shadow
  SET_OP_AMOUNT_FIELD(state, item) {
    state.op_amount_field = item
  },

  // eslint-disable-next-line no-shadow
  SET_DESTINATION_ID_FIELD(state, item) {
    state.destination_id_field = item
  },

  // eslint-disable-next-line no-shadow
  SET_AMOUNT_FIELD(state, item) {
    state.amount_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_PAGINATE_FIELD(state, paginante) {
    state.paginate_field = paginante
  },

  // eslint-disable-next-line no-shadow
  SET_OP_API_ID_FIELD(state, item) {
    state.op_api_id_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_BASE_AMOUNT_FIELD(state, item) {
    state.base_amount_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_META_DOCUMENT(state, metaDocument) {
    state.metaDocument = metaDocument
  },

  SET_FILTER(state, item) {
    state.filterStat = item
  },

  SET_FILTER_WITHDRAW(state, item) {
    state.filterWithdraw = item
  },

  SET_FILTER_WITHDRAW_VALUE(state, item) {
    state.filterWithdrawValueStat = item
  },

  SET_FILTER_DEPOSIT_VALUE(state, item) {
    state.filterDepositValueStat = item
  },

  SET_FILTER_REASON_VALUE(state, item) {
    state.filterReason = item
  },

  SET_FILTER_VALIDITY_VALUE(state, item) {
    state.filterValidity = item
  },

  SET_META_STATS(state, item) {
    state.metaStats = item
    state.catX = []
    state.dataY = []
    Object.keys(item).forEach(key => {
      state.catX.push(key)
      state.dataY.push(item[key])
    })
  },

  SET_META_WITHDRAW_STATS(state, item) {
    state.metaWithdrawStats = item
    state.catWithdrawX = []
    state.dataWithdrawY = []
    Object.keys(item).forEach(key => {
      state.catWithdrawX.push(key)
      state.dataWithdrawY.push(item[key])
    })
  },

  SET_META_WITHDRAW_VALUE_STATS(state, item) {
    state.metaWithdrawValueStats = item
    state.catWithdrawValueX = []
    state.dataWithdrawValueY = []
    state.dataWithdrawValueColor = []
    state.dataWithdrawValuePercent = []
    Object.keys(item).forEach(key => {
      state.catWithdrawValueX.push(key)
      state.dataWithdrawValueY.push(item[key].value)
      state.dataWithdrawValueColor.push(item[key].color)
      state.dataWithdrawValuePercent.push(item[key].percent)
    })
  },

  SET_META_DEPOSIT_VALUE_STATS(state, item) {
    state.metaDepositValueStats = item
    state.catDepositValueX = []
    state.dataDepositValueY = []
    state.dataDepositValueColor = []
    state.dataDepositValuePercent = []
    Object.keys(item).forEach(key => {
      state.catDepositValueX.push(key)
      state.dataDepositValueY.push(item[key].value)
      state.dataDepositValueColor.push(item[key].color)
      state.dataDepositValuePercent.push(item[key].percent)
    })
  },

  SET_META_REASON_VALUE_STATS(state, item) {
    state.metaReasonStat = item
    state.catReasonValueX = []
    state.dataReasonValueY = []
    state.dataReasonValueColor = []
    state.dataReasonValuePercent = []
    Object.keys(item).forEach(key => {
      state.catReasonValueX.push(key)
      state.dataReasonValueY.push(item[key].value)
      state.dataReasonValueColor.push(item[key].color)
      state.dataReasonValuePercent.push(item[key].percent)
    })
  },

  SET_META_VALIDITY_VALUE_STATS(state, item) {
    state.metaValidityStat = item
    state.catValidityValueX = []
    state.dataValidityValueY = []
    state.dataValidityValueColor = []
    state.dataValidityValuePercent = []
    Object.keys(item).forEach(key => {
      state.catValidityValueX.push(key)
      state.dataValidityValueY.push(item[key].value)
      state.dataValidityValueColor.push(item[key].color)
      state.dataValidityValuePercent.push(item[key].percent)
    })
  },

  SET_META_GLOBAL_STATS(state, item) {
    state.metaGlobalStat = item
    state.globalStatKeys = []
    state.globalStatValue = []
    Object.keys(item).forEach(key => {
      state.globalStatKeys.push(key)
      state.globalStatValue.push(item[key])
    })
  },

  // eslint-disable-next-line no-shadow
  SET_META_OP_API(state, item) {
    state.metaOpApi = item
  },

  // eslint-disable-next-line no-shadow
  SET_META_DOCUMENT_TYPE(state, item) {
    state.metaDocumentType = item
  },

  // eslint-disable-next-line no-shadow
  SET_FIELD(state, field) {
    state.field = field
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
