import axios from '@axios'

export default {
  getApiTokens(page, field) {
    return axios.post(`admin/api_tokens/list?page=${page}`, field)
  },

  getApiToken(id) {
    return axios.get(`admin/api_tokens/${id}`)
  },

  viewTokenKeys(id) {
    return axios.get(`admin/api_tokens/keys/${id}`)
  },

  hideTokenKeys(id) {
    return axios.get(`admin/api_tokens/hidekeys/${id}`)
  },

  updateTokenKeys(apiToken) {
    return axios.put(`admin/api_tokens/updatekeys/${apiToken.id}`, apiToken)
  },
}
