import cityService from '@/store/services/cityService'

const state = {
  field: {
    keyword: null,
    paginate: 10,
  },
  fieldSearch: {
    city: null,
  },
  keyword_field: null,
  city_field: null,
  paginate_field: null,
  city: null,
  metaCity: null,
  result: null,
}

const getters = {
  searchedCities: state => (state.result ? state.result : []),
  cities: state => (state.metaCity ? state.metaCity.data : []),
  current_page: state => (state.metaCity ? state.metaCity.current_page : 0),
  last_page: state => (state.metaCity ? state.metaCity.last_page : 0),
  per_page: state => (state.metaCity ? state.metaCity.per_page : 0),
}

const actions = {
  // eslint-disable-next-line no-shadow
  getCitiesList({ commit, state }, { page, field }) {
    if (state.metaCity && state.metaCity.current_page === page
      && state.paginate_field === field.paginate && state.keyword === field.keyword_field
      && state.keyword_field === field.keyword) return state.metaCity

    return cityService.getCities(page, field).then(({ data }) => {
      commit('SET_META_CITY', data.result)
      commit('SET_FIELD', field)
      commit('SET_KEYWORD_FIELD', field.keyword)

      return data
    })
  },

  // eslint-disable-next-line no-unused-vars,no-shadow
  searchCityWithMap({ commit, state }, field) {
    return cityService.searchCity(field).then(({ data }) => {
      commit('SET_RESULT', data.result)

      return data
    })
  },

}

const mutations = {
  // eslint-disable-next-line no-shadow
  SET_CAR(state, item) {
    state.city = item
  },
  // eslint-disable-next-line no-shadow
  SET_KEYWORD_FIELD(state, item) {
    state.keyword_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_PAGINATE_FIELD(state, paginante) {
    state.paginate_field = paginante
  },
  // eslint-disable-next-line no-shadow
  SET_META_CITY(state, metaCity) {
    state.metaCity = metaCity
  },

  // eslint-disable-next-line no-shadow
  SET_FIELD(state, field) {
    state.field = field
  },

  // eslint-disable-next-line no-shadow
  SET_FIELD_SEARCH(state, field) {
    state.fieldSearch = field
  },

  // eslint-disable-next-line no-shadow
  SET_CITY_FIELD(state, field) {
    state.city_field = field
  },

  // eslint-disable-next-line no-shadow
  SET_RESULT(state, field) {
    state.result = field
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
