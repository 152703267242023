import axios from '@axios'

export default {
  getApis(page, field) {
    return axios.post(`admin/apis/list?page=${page}`, field)
  },

  getApi(id) {
    return axios.get(`admin/apis/${id}`)
  },

  activateApi(id) {
    return axios.put(`admin/apis/activate/${id}`)
  },

  updateApi(operator) {
    return axios.put(`admin/apis/${operator.id}`, operator)
  },
}
