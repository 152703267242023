import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'
import user from './modules/user'
import road from './modules/road'
import car from './modules/car'
import destination from '@/store/modules/destination'
import alert from '@/store/modules/alert'
import device from '@/store/modules/device'
import proxiMargin from '@/store/modules/proxiMargin'
import userRoad from '@/store/modules/userRoad'
import city from '@/store/modules/city'
import document from '@/store/modules/document'
import search from '@/store/modules/search'
import campaign from '@/store/modules/campaign'
import payment from '@/store/modules/payment'
import setting from '@/store/modules/setting'
import roadPayment from '@/store/modules/roadPayment'
import operator from '@/store/modules/operator'
import upload from '@/store/modules/upload'
import api from '@/store/modules/api'
import apiProduct from '@/store/modules/apiProduct'
import apiToken from '@/store/modules/apiToken'
import paymentStatus from '@/store/modules/paymentStatus'
import recovery from '@/store/modules/recovery'
import statusRec from '@/store/modules/statusRec'
import statusRecovery from '@/store/modules/statusRecovery'
import reductCode from '@/store/modules/reductCode'
import status from '@/store/modules/status'
import reductCodeUser from '@/store/modules/reductCodeUser'
import notification from '@/store/modules/notification'
import locked from '@/store/modules/locked'
import recType from '@/store/modules/recType'
import payReason from '@/store/modules/payReason'
import role from '@/store/modules/role'
import action from '@/store/modules/action'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    appConfig: appConfigStoreModule,
    app,
    user,
    road,
    car,
    destination,
    alert,
    device,
    proxiMargin,
    userRoad,
    city,
    payment,
    document,
    search,
    setting,
    roadPayment,
    operator,
    upload,
    api,
    apiProduct,
    apiToken,
    paymentStatus,
    recovery,
    statusRec,
    statusRecovery,
    reductCode,
    status,
    reductCodeUser,
    notification,
    locked,
    recType,
    payReason,
    role,
    campaign,
    action,
  },
})
