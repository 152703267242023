import { forEach } from 'core-js/stable/dom-collections'
import searchService from '@/store/services/searchService'
import store from '@/store'

const state = {
  field: {
    city: null,
    nb_passagers: null,
    start_date: null,
    min_amount: null,
    max_amount: null,
    user_id: null,
    car: null,
    paginate: 10,
    destination: null,
  },
  city_field: null,
  start_date_field: null,
  created_at_field: null,
  nb_passagers_field: null,
  min_amount_field: null,
  max_amount_field: null,
  user_id_field: null,
  car_field: null,
  paginate_field: null,
  destination_field: null,
  metaSearch: null,
  search: {
    id: null,
    city: {
      id: null,
      name: null,
      region: null,
      altitude: null,
      longitude: null,
      country_code: null,
      osm_id: null,
      osm_type: null,
      place: null,
    },
    destination: {
      id: null,
      name: null,
      region: null,
      altitude: null,
      longitude: null,
      country_code: null,
      osm_id: null,
      osm_type: null,
      place: null,
    },
    user: {
      profile_picture_path: null,
    },
  },
  osmRoute: {
    distance: null,
    duration: null,
    url: null,
  },
  gmapKey: null,
  coordinates: [],
  refreshSearchCount: 0,
  refreshSearchId: null
}

const getters = {
  searches: state => (state.metaSearch ? state.metaSearch.data : []),
  current_page: state => (state.metaSearch ? state.metaSearch.current_page : 0),
  last_page: state => (state.metaSearch ? state.metaSearch.last_page : 0),
  per_page: state => (state.metaSearch ? state.metaSearch.per_page : 0),
  total: state => (state.metaSearch ? state.metaSearch.total : 0),
}

const actions = {
  // eslint-disable-next-line no-shadow
  getSearchesList({ commit, state }, { page, field }) {
    if (state.metaSearch && state.metaSearch.current_page === page
      && state.city_field === field.city && state.paginate_field === field.paginate
      && state.start_date_field === field.start_date && state.nb_passagers_field === field.nb_passagers
      && state.created_at_field === field.created_at && state.nb_passagers_field === field.nb_passagers
      && state.min_amount_field === field.min_amount && state.max_amount_field === field.max_amount
      && state.user_id_field === field.user_id && state.car_field === field.car
      && state.destination_field === field.destination) return state.metaSearch

    return searchService.getSearches(page, field).then(({ data }) => {
      commit('SET_META_ROAD', data.result)
      commit('SET_CITY_FIELD', field.city)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_START_DATE_FIELD', field.start_date)
      commit('SET_CREATED_AT_FIELD', field.created_at)
      commit('SET_NB_PASSAGERS_FIELD', field.nb_passagers)
      commit('SET_FIELD', field)
      commit('SET_MIN_AMOUNT_FIELD', field.min_amount)
      commit('SET_MAX_AMOUNT_FIELD', field.max_amount)
      commit('SET_USER_ID_FIELD', field.user_id)
      commit('SET_CAR_FIELD', field.car)
      commit('SET_DESTINATION_FIELD', field.destination)

      return data
    })
  },

  refreshList({ commit, state }, { page, field }) {
    return searchService.getSearches(page, field).then(({ data }) => {
      commit('SET_META_ROAD', data.result)
      commit('SET_CITY_FIELD', field.city)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_START_DATE_FIELD', field.start_date)
      commit('SET_NB_PASSAGERS_FIELD', field.nb_passagers)
      commit('SET_FIELD', field)
      commit('SET_MIN_AMOUNT_FIELD', field.min_amount)
      commit('SET_MAX_AMOUNT_FIELD', field.max_amount)
      commit('SET_USER_ID_FIELD', field.user_id)
      commit('SET_CAR_FIELD', field.car)
      commit('SET_DESTINATION_FIELD', field.destination)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  getSearchById({ commit, state }, id) {
    return searchService.getSearch(id).then(({ data }) => {
      commit('SET_ROAD', data.result)

      return data
    })
  },

  // eslint-disable-next-line no-unused-vars,no-shadow
  getOsmRouteById({ commit, state }, id) {
    return searchService.getDistance(id).then(({ data }) => {
      commit('SET_OSM_ROUTE', data.result)
      commit('SET_COORDINATE', data.result)

      return data
    })
  },

  // eslint-disable-next-line no-unused-vars,no-shadow
  getGoogleMapKey({ commit, state }, id) {
    return searchService.getGMapKey().then(({ data }) => {
      commit('SET_GMAP_KEY', data.result)

      return data
    })
  },

  // eslint-disable-next-line no-shadow
  newSearchEvent({ commit, state }, id) {
    return searchService.getSearch(id).then(({ data }) => {
      commit('ADD_ROAD', data.result)

      return data
    })
  },

}

const mutations = {
  // eslint-disable-next-line no-shadow
  ADD_ROAD(state, search) {
    if (state.metaSearch) {
      if (state.metaSearch.per_page === state.field.paginate) state.metaSearch.data.pop()
      state.metaSearch.data.unshift(search)
    } else {
      store.dispatch('search/getSearchesList', { page: 1, field: state.field }).catch(err => {
        console.log(err)
      })
    }
  },

  // eslint-disable-next-line no-shadow
  SET_GMAP_KEY(state, item) {
    state.gmapKey = item
  },
  // eslint-disable-next-line no-shadow
  SET_ROAD(state, item) {
    state.search = item
  },
  // eslint-disable-next-line no-shadow
  SET_OSM_ROUTE(state, item) {
    state.osmRoute = item
  },

  // eslint-disable-next-line no-shadow
  SET_COORDINATE(state, item) {
    state.coordinates.splice(0, state.coordinates.length)
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < item.coordinates.length; i++) {
      state.coordinates.push([item.coordinates[i].start_location.lat, item.coordinates[i].start_location.lng])
      state.coordinates.push([item.coordinates[i].end_location.lat, item.coordinates[i].end_location.lng])
    }
  },

  // eslint-disable-next-line no-shadow
  SET_DESTINATION_FIELD(state, item) {
    state.destination_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_CAR_FIELD(state, item) {
    state.car_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_USER_ID_FIELD(state, item) {
    state.user_id_field = item
  },


  // eslint-disable-next-line no-shadow
  SET_REFRESH_ROAD_ID(state, item) {
    state.refreshSearchId = item
  },

  // eslint-disable-next-line no-shadow
  INCREMENT_REFRESH_ROAD_COUNT(state) {
    state.refreshSearchCount++
  },
  // eslint-disable-next-line no-shadow
  SET_MAX_AMOUNT_FIELD(state, item) {
    state.max_amount_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_MIN_AMOUNT_FIELD(state, item) {
    state.min_amount_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_NB_PASSAGERS_FIELD(state, item) {
    state.nb_passagers_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_START_DATE_FIELD(state, item) {
    state.start_date_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_PAGINATE_FIELD(state, paginante) {
    state.paginate_field = paginante
  },
  // eslint-disable-next-line no-shadow
  SET_CITY_FIELD(state, city) {
    state.city_field = city
  },
  // eslint-disable-next-line no-shadow
  SET_META_ROAD(state, metaSearch) {
    state.metaSearch = metaSearch
  },

  // eslint-disable-next-line no-shadow
  SET_FIELD(state, field) {
    state.field = field
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
