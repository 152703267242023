import lockedService from '@/store/services/lockedService'

const state = {
  field: {
    is_active: null,
    keyword: null,
    user_id: null,
    paginate: 10,
  },
  is_active_field: null,
  keyword_field: null,
  user_id_field: null,
  paginate_field: null,
  metaLocked: null,
  locked: null,
}

const getters = {
  lockeds: state => (state.metaLocked ? state.metaLocked.data : []),
  current_page: state => (state.metaLocked ? state.metaLocked.current_page : 0),
  last_page: state => (state.metaLocked ? state.metaLocked.last_page : 0),
  per_page: state => (state.metaLocked ? state.metaLocked.per_page : 0),
}

const actions = {
  // eslint-disable-next-line no-shadow
  getLockedsList({ commit, state }, { page, field }) {
    if (state.metaLocked && state.metaLocked.current_page === page
      && state.is_active_field === field.is_active && state.paginate_field === field.paginate
      && state.keyword_field === field.keyword && state.user_id_field === field.user_id) return state.metaLocked

    return lockedService.getLockeds(page, field).then(({ data }) => {
      commit('SET_META_LOCKED', data.result)
      commit('SET_IS_ACTIVE_FIELD', field.is_active)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_LOCKED_FIELD', field.keyword)
      commit('SET_FIELD', field)
      commit('SET_USER_ID_FIELD', field.user_id)

      return data
    })
  },

  refreshLockedsList({ commit, state }, { page, field }) {
    return lockedService.getLockeds(page, field).then(({ data }) => {
      commit('SET_META_LOCKED', data.result)
      commit('SET_IS_ACTIVE_FIELD', field.is_active)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_LOCKED_FIELD', field.keyword)
      commit('SET_FIELD', field)
      commit('SET_USER_ID_FIELD', field.user_id)

      return data
    })
  },
}

const mutations = {
  // eslint-disable-next-line no-shadow
  SET_DEVICE(state, item) {
    state.locked = item
  },
  // eslint-disable-next-line no-shadow
  SET_USER_ID_FIELD(state, item) {
    state.user_id_field = item
  },

  // eslint-disable-next-line no-shadow
  SET_LOCKED_FIELD(state, item) {
    state.keyword_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_PAGINATE_FIELD(state, paginante) {
    state.paginate_field = paginante
  },
  // eslint-disable-next-line no-shadow
  SET_IS_ACTIVE_FIELD(state, is_active) {
    state.is_active_field = is_active
  },
  // eslint-disable-next-line no-shadow
  SET_META_LOCKED(state, metaLocked) {
    state.metaLocked = metaLocked
  },

  // eslint-disable-next-line no-shadow
  SET_FIELD(state, field) {
    state.field = field
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
