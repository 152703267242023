import axios from '@axios'

export default {
  getSettings(page, field) {
    return axios.post(`admin/settings/list?page=${page}`, field)
  },

  getSetting(id) {
    return axios.get(`admin/settings/${id}`)
  },

  updateSetting(setting) {
    return axios.put(`admin/settings/${setting.id}`, setting)
  },
}
