const paiementpages = [
  {
    path: '/documents',
    name: 'documents',
    component: () => import('@/views/documents/Document.vue'),
    meta: {
      layout: 'content',
      title: "Documents",
    },
  },
  {
    path: '/search',
    name: 'searches',
    component: () => import('@/views/searches/Search.vue'),
    meta: {
      layout: 'content',
      title: "Research",
    },
  },
  {
    path: '/paiements/:id/show',
    name: 'showpayment',
    component: () => import('@/views/payment/PaymentDetails.vue'),
    meta: {
      layout: 'content',
      title: "Détails d'un paiement",
    },
  },
  {
    path: '/operators',
    name: 'operator',
    component: () => import('@/views/operator/Operator.vue'),
    meta: {
      layout: 'content',
      title: "Opérateur",
    },
  },
  {
    path: '/operators/:id/show',
    name: 'showoperator',
    component: () => import('@/views/operator/OperatorDetails.vue'),
    meta: {
      layout: 'content',
      title: "Détails d'un opérateur",
    },
  },
]

export default paiementpages
