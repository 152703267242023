<template>
  <v-menu
    offset-y
    left
    nudge-bottom="22"
    :elevation="$vuetify.theme.dark ? 9 : 8"
    content-class="list-style notification-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        :content="$store.state.notification.total_notification"
        :color="$store.state.notification.total_notification > 0 ? 'error' : ''"
        offset-x="12"
        offset-y="6"
      >
        <v-icon
          v-bind="attrs"
          v-on="on"
        >
          {{ icons.mdiBellOutline }}
        </v-icon>
      </v-badge>
    </template>
    <v-card class="app-bar-notification-content-container">
      <perfect-scrollbar
        class="ps-user-notifications"
        :options="perfectScrollbarOptions"
      >
        <v-list class="py-0">
          <!-- Header -->
          <v-list-item
            class="d-flex"
            link
          >
            <div class="d-flex align-center justify-space-between flex-grow-1">
              <span class="font-weight-semibold">Notifications</span>
              <v-chip
                class="v-chip-light-bg primary--text font-weight-semibold"
                small
              >
                {{ $store.state.notification.total_notification }} Nouvelles
              </v-chip>
            </div>
          </v-list-item>
          <v-divider></v-divider>

          <!-- Notifications -->
          <v-card
            v-scroll.self="onScroll"
            class="overflow-y-auto"
            max-height="450"
          >
            <template v-for="(notification, index) in $store.state.notification.items">
              <v-list-item
                :key="index"
                link
                three-line
                :style="notification.read_at !== null ? 'background-color: rgb(54 185 88 / 1%)' :
                  'background-color: rgb(54 185 88 / 9%)'"
                @click="getNotification(notification)"
              >
                <!-- Content -->
                <v-list-item-icon>
                  <v-icon
                    v-if="notification.model_type === 'App\\Models\\User'"
                    v-text="icons.mdiCardAccountDetails"
                  ></v-icon>
                  <v-icon
                    v-else-if="notification.model_type === 'App\\Models\\Recovery'"
                    v-text="icons.mdiTicketAccount"
                  ></v-icon>
                  <v-icon
                    v-else-if="notification.model_type === 'App\\Models\\Road'"
                    v-text="icons.mdiRoad"
                  ></v-icon>
                  <v-icon
                    v-else
                    v-text="icons.mdiCash"
                  ></v-icon>
                </v-list-item-icon>
                <v-list-item-content class="d-block">
                  <v-list-item-title class="text-sm font-weight-semibold">
                    {{ notification.data.title }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-sm">
                    {{ formatMessage(notification.data.message).part1 }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="text-sm">
                    {{ formatMessage(notification.data.message).part2 }}
                  </v-list-item-subtitle>
                </v-list-item-content>

                <!-- Item Action/Time -->
                <v-list-item-action>
                  <span class="text--secondary text-xs">{{ formatdate(notification.created_at) }}</span>
                </v-list-item-action>
              </v-list-item>

              <v-divider></v-divider>
            </template>
          </v-card>
          <v-list-item
            v-show="loading"
            key="loader"
            class="read-all-btn-list-item"
          >
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
          </v-list-item>

          <v-list-item
            key="read-all-btn"
            class="read-all-btn-list-item"
          >
            <v-btn
              block
              color="primary"
              @click="openNotifications"
            >
              Voir tout
            </v-btn>
          </v-list-item>
        </v-list>
      </perfect-scrollbar>
    </v-card>
  </v-menu>
</template>

<script>
import {
  mdiBellOutline, mdiCardAccountDetails, mdiTicketAccount, mdiCash, mdiRoad
} from '@mdi/js'
import { getInitialName } from '@core/utils'

// 3rd Party
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import { notify } from '@core/dynamicComponents/dynamicComponents'
import { onMounted, ref, watch } from '@vue/composition-api/dist/vue-composition-api'
import { formatDateToMonthShort } from '@core/utils/filter'
import store from '@/store'
import router from '@/router'

export default {
  components: {
    // 3rd Party
    PerfectScrollbar,
  },
  setup() {
    const selected = ref([])
    const loading = ref(false)
    const items = ref([])
    const formatdate = (date = null) => (date !== null ? formatDateToMonthShort(date) : null)

    const scrollInvoked = ref(0)

    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const getList = (page = 1) => {
      loading.value = true
      store.dispatch('notification/getNotificationsList', { page, field: store.state.notification.field }).then(() => {
        loading.value = false
      }).catch(err => {
        console.log(err)
        notify(false, true, err.response.data.message)
        loading.value = false
      })
    }

    const refreshList = (page = 1) => {
      loading.value = true
      store.dispatch('notification/refreshNotificationsList', { page, field: store.state.notification.field }).then(() => {
        loading.value = false
      }).catch(err => {
        console.log(err)
        notify(false, true, err.response.data.message)
        loading.value = false
      })
    }

    const formatMessage = message => {
      let part1 = ''
      let part2 = ''
      if (message !== null) {
        const words = message.split(' ')

        for (let i = 0; i < words.length; i++) {
          if (i < 10) part1 = `${part1} ${words[i]}`
          else part2 = `${part2} ${words[i]}`
        }
      }

      return { part1, part2 }
    }

    const getNotification = item => {
      store.dispatch('notification/getNotificationById', item).then(() => {
        switch (item.model_type) {
          case 'App\\Models\\User':
            if (router.currentRoute.name !== 'showuser') {
              router.push({ name: 'showuser', params: { id: item.model_id } })
            } else if (router.currentRoute.params.id !== item.model_id) {
              router.replace({ name: 'showuser', params: { id: item.model_id } })
              store.commit('user/INCREMENT_REFRESH_USER_COUNT')
              store.commit('user/SET_REFRESH_USER_ID', item.model_id)
            }
            break
          case 'App\\Models\\Recovery':
            if (router.currentRoute.name !== 'showrecovery') {
              router.push({ name: 'showrecovery', params: { id: item.model_id } })
            } else if (router.currentRoute.params.id !== item.model_id) {
              router.replace({ name: 'showrecovery', params: { id: item.model_id } })
              store.commit('recovery/INCREMENT_REFRESH_ITEM_COUNT')
              store.commit('recovery/SET_REFRESH_ITEM_ID', item.model_id)
            }
            break
          case 'App\\Models\\Payment':
            if (router.currentRoute.name !== 'showpayment') {
              router.push({ name: 'showpayment', params: { id: item.model_id } })
            } else if (router.currentRoute.params.id !== item.model_id) {
              router.replace({ name: 'showpayment', params: { id: item.model_id } })
              store.commit('payment/INCREMENT_REFRESH_PAY_COUNT')
              store.commit('payment/SET_REFRESH_PAY_ID', item.model_id)
            }
            break
          case 'App\\Models\\Road':
            if (router.currentRoute.name !== 'showroad') {
              router.push({ name: 'showroad', params: { id: item.model_id } })
            } else if (router.currentRoute.params.id !== item.model_id) {
              router.replace({ name: 'showroad', params: { id: item.model_id } })
              store.commit('road/INCREMENT_REFRESH_ROAD_COUNT')
              store.commit('road/SET_REFRESH_ROAD_ID', item.model_id)
            }
            break
          default:
            console.log('Nothing')
        }
      }).catch(error => {
        console.log(error)
      })
    }

    onMounted(() => {
      getList()
    })

    watch(() => selected, newId => {
      console.log(newId)
    }, { deep: true })

    watch(() => store.state.user.lang, lang => {
      refreshList(store.getters['notification/current_page'])
    })

    const onScroll = event => {
      scrollInvoked.value++
      const { scrollTop, offsetHeight, scrollHeight } = event.target

      if ((scrollTop + offsetHeight) >= scrollHeight) {
        if (store.getters['notification/total'] !== store.getters['notification/last_page']) {
          getList(store.getters['notification/current_page'] + 1)
        }
      }
    }

    const openNotifications = () => {
      router.push({ name: 'notifications' })
    }

    return {
      loading,
      openNotifications,
      items,
      onScroll,
      formatMessage,
      selected,
      getNotification,
      formatdate,
      getInitialName,
      perfectScrollbarOptions,

      icons: {
        mdiBellOutline,
        mdiCardAccountDetails,
        mdiTicketAccount,
        mdiCash,
        mdiRoad
      },
    }
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.app-bar-notification-content-container {
  .read-all-btn-list-item {
    padding-top: 14px;
    padding-bottom: 14px;
    min-height: unset;
  }
}

.ps-user-notifications {
  max-height: calc(var(--vh, 1vh) * 80);
}

.notification-menu-content {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    min-width: calc(100vw - (1.5rem * 2)) !important;
    left: 50% !important;
    transform: translateX(-50%);
  }

}
</style>
