import payReasonService from '@/store/services/payReasonService'

const state = {
  field: {
    keyword: null,
    paginate: 10,
  },
  keyword_field: null,
  paginate_field: null,
  metaPayReason: null,
  payReason: null,
}

const getters = {
  payReasons: state => (state.metaPayReason ? state.metaPayReason.data : []),
  current_page: state => (state.metaPayReason ? state.metaPayReason.current_page : 0),
  last_page: state => (state.metaPayReason ? state.metaPayReason.last_page : 0),
  per_page: state => (state.metaPayReason ? state.metaPayReason.per_page : 0),
  total: state => (state.metaPayReason ? state.metaPayReason.total : 0),
}

const actions = {
  // eslint-disable-next-line no-shadow
  getPayReasonList({ commit, state }, { page, field }) {
    if (state.metaPayReason && state.metaPayReason.current_page === page
      && state.keyword_field === field.keyword && state.paginate_field === field.paginate) return state.metaPayReason

    return payReasonService.getPayReasonList(page, field).then(({ data }) => {
      commit('SET_META_PAYREASON', data.result)
      commit('SET_KEYWORD_FIELD', field.keyword)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_FIELD', field)

      return data
    })
  },

  refreshPayReasonsList({ commit, state }, { page, field }) {
    return payReasonService.getPayReasonList(page, field).then(({ data }) => {
      commit('SET_META_PAYREASON', data.result)
      commit('SET_KEYWORD_FIELD', field.keyword)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_FIELD', field)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  getPayReasonById({ commit, state }, id) {
    return payReasonService.getPayReason(id).then(({ data }) => {
      commit('SET_PAYREASON', data.result)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  updatePayReason({ commit, state }, field) {
    return payReasonService.updatePayReason(field).then(({ data }) => {
      commit('UPDATE_PAYREASON', { payReason: data.result, item: field })

      return data
    })
  },

}

const mutations = {

  // eslint-disable-next-line no-shadow
  UPDATE_PAYREASON(state, { payReason, item }) {
    if (state.metaPayReason) { state.metaPayReason.data.splice(state.metaPayReason.data.findIndex(t => t.id === item.id), 1, payReason) }
  },

  // eslint-disable-next-line no-shadow
  SET_PAYREASON(state, item) {
    state.payReason = item
  },

  // eslint-disable-next-line no-shadow
  SET_PAGINATE_FIELD(state, paginante) {
    state.paginate_field = paginante
  },
  // eslint-disable-next-line no-shadow
  SET_KEYWORD_FIELD(state, keyword) {
    state.keyword_field = keyword
  },
  // eslint-disable-next-line no-shadow
  SET_META_PAYREASON(state, metaPayReason) {
    state.metaPayReason = metaPayReason
  },

  // eslint-disable-next-line no-shadow
  SET_FIELD(state, field) {
    state.field = field
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
