import { notify } from '@core/dynamicComponents/dynamicComponents'
import apiProductService from '@/store/services/apiProductService'

const state = {
  field: {
    keyword: null,
    paginate: 10,
    api_id: null,
    operator_id: null,
  },
  keyword_field: null,
  operator_id_field: null,
  api_id_field: null,
  paginate_field: null,
  metaApiProduct: null,
  apiProduct: {
    id: null,
    label: null,
    code: null,
    description: null,
    api_id: null,
  },
}

const getters = {
  apiProducts: state => (state.metaApiProduct ? state.metaApiProduct.data : []),
  current_page: state => (state.metaApiProduct ? state.metaApiProduct.current_page : 0),
  last_page: state => (state.metaApiProduct ? state.metaApiProduct.last_page : 0),
  per_page: state => (state.metaApiProduct ? state.metaApiProduct.per_page : 0),
}

const actions = {
  // eslint-disable-next-line no-shadow
  getApisList({ commit, state }, { page, field }) {
    if (state.metaApiProduct && state.metaApiProduct.current_page === page
      && state.operator_id_field === field.operator_id && state.api_id_field === field.api_id
      && state.keyword_field === field.keyword && state.paginate_field === field.paginate) return state.metaApiProduct

    return apiProductService.getApiProducts(page, field).then(({ data }) => {
      commit('SET_META_API_PRODUCT', data.result)
      commit('SET_KEYWORD_FIELD', field.keyword)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_OPERATOR_ID_FIELD', field.operator_id)
      commit('SET_API_ID_FIELD', field.api_id)
      commit('SET_FIELD', field)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  getApiById({ commit, state }, id) {
    return apiProductService.getApiProduct(id).then(({ data }) => {
      commit('SET_API_PRODUCT', data.result)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  viewKey({ commit, state }, id) {
    return apiProductService.viewProductKeys(id).then(({ data }) => {
      commit('SET_API_PRODUCT', data.result)

      return data
    })
  },

  hideKey({ commit, state }, id) {
    return apiProductService.hideProductKeys(id).then(({ data }) => data)
  },

  updateApiProduct({ commit, state }, apiProduct) {
    return apiProductService.updateApiProduct(apiProduct).then(({ data }) => {
      commit('UPDATE_API_PRODUCT', { api: data.result, item: apiProduct })
      notify(true, true, data.message)

      return data
    })
  },

  // eslint-disable-next-line no-unused-vars,no-shadow
  updateKey({ commit, state }, apiProduct) {
    return apiProductService.updateProductKeys(apiProduct).then(({ data }) => {
      notify(true, true, data.message)

      return data
    })
  },

}

const mutations = {
  UPDATE_API_PRODUCT(state, { api, item }) {
    if (state.metaApiProduct) { state.metaApiProduct.data.splice(state.metaApiProduct.data.findIndex(t => t.id === item.id), 1, api) }
  },
  // eslint-disable-next-line no-shadow
  SET_API_PRODUCT(state, item) {
    state.apiProduct = item
  },
  // eslint-disable-next-line no-shadow
  SET_PAGINATE_FIELD(state, paginante) {
    state.paginate_field = paginante
  },
  // eslint-disable-next-line no-shadow
  SET_KEYWORD_FIELD(state, keyword) {
    state.keyword_field = keyword
  },

  // eslint-disable-next-line no-shadow
  SET_OPERATOR_ID_FIELD(state, item) {
    state.operator_id_field = item
  },

  // eslint-disable-next-line no-shadow
  SET_API_ID_FIELD(state, item) {
    state.api_id_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_META_API_PRODUCT(state, metaApiProduct) {
    state.metaApiProduct = metaApiProduct
  },

  // eslint-disable-next-line no-shadow
  SET_FIELD(state, field) {
    state.field = field
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
