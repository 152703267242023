import axios from '@axios'

export default {
  getAlerts(page, field) {
    return axios.post(`platform/alerts/list?page=${page}`, field)
  },

  getAlert(id) {
    return axios.get(`platform/alerts/${id}`)
  },
}
