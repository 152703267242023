const paiementpages = [
  {
    path: '/paiements',
    name: 'paiements',
    component: () => import('@/views/payment/Payment.vue'),
    meta: {
      layout: 'content',
      title: "Paiements",
    },
  },
  {
    path: '/paiements/:id/show',
    name: 'showpayment',
    component: () => import('@/views/payment/PaymentDetails.vue'),
    meta: {
      layout: 'content',
      title: "Détails d'un paiement",
    },
  },
  {
    path: '/operators',
    name: 'operator',
    component: () => import('@/views/operator/Operator.vue'),
    meta: {
      layout: 'content',
      title: "Opérateur",
    },
  },
  {
    path: '/operators/:id/show',
    name: 'showoperator',
    component: () => import('@/views/operator/OperatorDetails.vue'),
    meta: {
      layout: 'content',
      title: "Détails d'un opérateur",
    },
  },
]

export default paiementpages
