import { notify } from '@core/dynamicComponents/dynamicComponents'
import roleService from '@/store/services/roleService'

const state = {
  field: {
    label: null,
    code: null,
    paginate: 10,
    to_store: false,
  },
  label_field: null,
  code_field: null,
  paginate_field: null,
  to_store_field: null,
  metaRole: null,
  role: null,
}

const getters = {
  roles: state => (state.metaRole ? state.metaRole.data : []),
  current_page: state => (state.metaRole ? state.metaRole.current_page : 0),
  last_page: state => (state.metaRole ? state.metaRole.last_page : 0),
  per_page: state => (state.metaRole ? state.metaRole.per_page : 0),
  total: state => (state.metaRole ? state.metaRole.total : 0),
}

const actions = {
  // eslint-disable-next-line no-shadow
  getRoleList({ commit, state }, { page, field }) {
    if (state.metaRole && state.metaRole.current_page === page
      && state.label_field === field.label
      && state.code_field === field.code
      && state.to_store_field === field.to_store
      && state.paginate_field === field.paginate) return state.metaRole

    return roleService.getRoles(page, field).then(({ data }) => {
      commit('SET_META_ROLE', data.result)
      commit('SET_LABEL_FIELD', field.label)
      commit('SET_CODE_FIELD', field.code)
      commit('SET_TO_STORE_FIELD', field.to_store)
      commit('SET_PAGINATE_FIELD', field.paginate)

      return data
    })
  },

  refreshRoleList({ commit, state }, { page, field }) {
    return roleService.getRoles(page, field).then(({ data }) => {
      commit('SET_META_ROLE', data.result)
      commit('SET_LABEL_FIELD', field.label)
      commit('SET_CODE_FIELD', field.code)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_TO_STORE_FIELD', field.to_store)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  getRoleById({ commit, state }, id) {
    return roleService.getRole(id).then(({ data }) => {
      commit('SET_SETTING', data.result)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  updateRole({ commit, state }, role) {
    return roleService.updateRole(role).then(({ data }) => {
      commit('UPDATE_ROLE', { role: data.result, item: role })

      // notify(true, true, data.message)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  addRole({ commit, state }, field) {
    return roleService.addRole(field).then(({ data }) => {
      commit('ADD_ROLE', data.result)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  deleteRole({ commit, state }, field) {
    return roleService.deleteRole(field).then(({ data }) => {
      commit('UPDATE_ROLE', { role: data.result, item: field })

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  restoreRole({ commit, state }, field) {
    return roleService.restoreRole(field).then(({ data }) => {
      commit('UPDATE_ROLE', { role: data.result, item: field })

      return data
    })
  },
}

const mutations = {
  // eslint-disable-next-line no-shadow
  ADD_ROLE(state, role) {
    state.metaRole.data.unshift(role)
  },

  // eslint-disable-next-line no-shadow
  UPDATE_ROLE(state, { role, item }) {
    if (state.metaRole) { state.metaRole.data.splice(state.metaRole.data.findIndex(t => t.id === item.id), 1, role) }
  },
  // eslint-disable-next-line no-shadow
  SET_SETTING(state, item) {
    state.role = item
  },

  // eslint-disable-next-line no-shadow
  SET_PAGINATE_FIELD(state, paginante) {
    state.paginate_field = paginante
  },

  // eslint-disable-next-line no-shadow
  SET_TO_STORE_FIELD(state, item) {
    state.to_store_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_LABEL_FIELD(state, label) {
    state.label_field = label
  },

  // eslint-disable-next-line no-shadow
  SET_CODE_FIELD(state, code) {
    state.code_field = code
  },
  // eslint-disable-next-line no-shadow
  SET_META_ROLE(state, metaRole) {
    state.metaRole = metaRole
  },

  // eslint-disable-next-line no-shadow
  SET_FIELD(state, field) {
    state.field = field
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
