import axios from '@axios'

export default {
  getRoles(page, field) {
    return axios.post(`admin/roles/list?page=${page}`, field)
  },

  getRole(id) {
    return axios.get(`admin/roles/${id}`)
  },

  updateRole(role) {
    return axios.put(`admin/roles/${role.id}`, role)
  },

  addRole(role) {
    return axios.post('admin/roles', role)
  },

  deleteRole(role) {
    return axios.delete(`admin/roles/${role.id}`)
  },

  restoreRole(role) {
    return axios.post(`admin/roles/${role.id}/restore`)
  },
}
