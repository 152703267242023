import proxiMarginService from '@/store/services/proxiMarginService'

const state = {
  field: {
    is_active: null,
    margin: null,
    max_distance: null,
    min_distance: null,
    paginate: 10,
  },
  is_active_field: null,
  agent_field: null,
  max_distance_field: null,
  min_distance_field: null,
  paginate_field: null,
  metaProxiMargin: null,
  proxiMargin: null,
}

const getters = {
  proxiMargins: state => (state.metaProxiMargin ? state.metaProxiMargin.data : []),
  current_page: state => (state.metaProxiMargin ? state.metaProxiMargin.current_page : 0),
  last_page: state => (state.metaProxiMargin ? state.metaProxiMargin.last_page : 0),
  per_page: state => (state.metaProxiMargin ? state.metaProxiMargin.per_page : 0),
  total: state => (state.metaProxiMargin ? state.metaProxiMargin.total : 0),
}

const actions = {
  // eslint-disable-next-line no-shadow
  getProxiMarginList({ commit, state }, { page, field }) {
    if (state.metaProxiMargin && state.metaProxiMargin.current_page === page
      && state.is_active_field === field.is_active && state.paginate_field === field.paginate
      && state.agent_field === field.margin && state.max_distance_field === field.max_distance
      && state.min_distance_field === field.min_distance) return state.metaProxiMargin

    return proxiMarginService.getProxiMargins(page, field).then(({ data }) => {
      commit('SET_META_PROXI_MARGIN', data.result)
      commit('SET_IS_ACTIVE_FIELD', field.is_active)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_AGENT_FIELD', field.margin)
      commit('SET_FIELD', field)
      commit('SET_MAX_DISTANCE_ID_FIELD', field.max_distance)
      commit('SET_MIN_DISTANCE_ID_FIELD', field.min_distance)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  getProxiMarginById({ commit, state }, id) {
    return proxiMarginService.getProxiMargin(id).then(({ data }) => {
      commit('SET_PROXI_MARGIN', data.result)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  updateProxiMargin({ commit, state }, proxiMargin) {
    return proxiMarginService.updateProxiMargin(proxiMargin).then(({ data }) => {
      commit('UPDATE_PROXY_MARGIN', { proxiMargin: data.result, item: proxiMargin })

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  addProxiMargin({ commit, state }, proxiMargin) {
    return proxiMarginService.addProxiMargin(proxiMargin).then(({ data }) => {
      commit('ADD_PROXY_MARGIN', data.result)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  activateProxiMargin({ commit, state }, proxiMargin) {
    return proxiMarginService.activateProxiMargin(proxiMargin).then(({ data }) => {
      commit('UPDATE_PROXY_MARGIN', { proxiMargin: data.result, item: proxiMargin })

      return data
    })
  },
}

const mutations = {
  // eslint-disable-next-line no-shadow
  ADD_PROXY_MARGIN(state, proxiMargin) {
    state.metaProxiMargin.data.unshift(proxiMargin)
  },

  // eslint-disable-next-line no-shadow
  UPDATE_PROXY_MARGIN(state, { proxiMargin, item }) {
    if (state.metaProxiMargin) { state.metaProxiMargin.data.splice(state.metaProxiMargin.data.findIndex(t => t.id === item.id), 1, proxiMargin) }
  },
  // eslint-disable-next-line no-shadow
  SET_PROXI_MARGIN(state, item) {
    state.proxiMargin = item
  },
  // eslint-disable-next-line no-shadow
  SET_MAX_DISTANCE_ID_FIELD(state, item) {
    state.max_distance_field = item
  },

  // eslint-disable-next-line no-shadow
  SET_MIN_DISTANCE_ID_FIELD(state, item) {
    state.min_distance_field = item
  },

  // eslint-disable-next-line no-shadow
  SET_AGENT_FIELD(state, item) {
    state.agent_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_PAGINATE_FIELD(state, paginante) {
    state.paginate_field = paginante
  },
  // eslint-disable-next-line no-shadow
  SET_IS_ACTIVE_FIELD(state, is_active) {
    state.is_active_field = is_active
  },
  // eslint-disable-next-line no-shadow
  SET_META_PROXI_MARGIN(state, metaProxiMargin) {
    state.metaProxiMargin = metaProxiMargin
  },

  // eslint-disable-next-line no-shadow
  SET_FIELD(state, field) {
    state.field = field
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
