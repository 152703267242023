import axios from '@axios'

export default {
  getRoads(page, field) {
    return axios.post(`platform/cars/list?page=${page}`, field)
  },

  getCar(car) {
    return axios.get(`platform/cars/${car.id}`)
  },
}
