import axios from '@axios'

export default {
  getReductCodeUsersList(page, field) {
    return axios.post(`platform/reduct_code_users/list?page=${page}`, field)
  },

  getReductCodeUser(id) {
    return axios.get(`platform/reduct_code_users/${id}`)
  },

  addReductCodeUser(field) {
    return axios.post('platform/reduct_code_users', field)
  },

  updateReductCodeUser(field) {
    return axios.put(`platform/reduct_code_users/${field.id}`, field)
  },

  deleteReductCodeUser(field) {
    return axios.delete(`platform/reduct_code_users/${field.id}`, field)
  },

  restoreReductCodeUser(field) {
    return axios.post(`platform/reduct_code_users/${field.id}/restore`)
  },
}
