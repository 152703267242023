import axios from '@axios'

export default {
  getRoads(page, field) {
    return axios.post(`platform/roads/list?page=${page}`, field)
  },

  getRoad(id) {
    return axios.get(`platform/roads/${id}`)
  },

  getDistance(id) {
    return axios.get(`platform/roads/distance/${id}`)
  },

  getGMapKey() {
    return axios.get('platform/roads/gmap/key')
  },
}
