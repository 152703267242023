import recoveryService from '@/store/services/recoveryService'
import store from '@/store'
import roadService from "@/store/services/roadService";

const state = {
  field: {
    amount: null,
    payment_id: null,
    user: null,
    admin: null,
    paginate: 10,
  },
  amount_field: null,
  user_field: null,
  payment_id_field: null,
  admin_field: null,
  paginate_field: null,
  metaRecovery: null,
  recovery: {
    user: {},
    admin: {},
    operator: {},
  },
  refreshItemCount: 0,
  refreshItemId: null,
  refreshListCount: 0,
}

const getters = {
  recoveries: state => (state.metaRecovery ? state.metaRecovery.data : []),
  current_page: state => (state.metaRecovery ? state.metaRecovery.current_page : 0),
  last_page: state => (state.metaRecovery ? state.metaRecovery.last_page : 0),
  per_page: state => (state.metaRecovery ? state.metaRecovery.per_page : 0),
  total: state => (state.metaRecovery ? state.metaRecovery.total : 0),
}

const actions = {
  // eslint-disable-next-line no-shadow
  getRecoveriesList({ commit, state }, { page, field }) {
    if (state.metaRecovery && state.metaRecovery.current_page === page
      && state.amount_field === field.amount && state.paginate_field === field.paginate
      && state.user_field === field.user && state.payment_id_field === field.payment_id
      && state.admin_field === field.admin) return state.metaRecovery

    return recoveryService.getRecoveries(page, field).then(({ data }) => {
      commit('SET_META_RECOVERY', data.result)
      commit('SET_AMOUNT_FIELD', field.amount)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_USER_FIELD', field.user)
      commit('SET_PAYMENT_ID_FIELD', field.payment_id)
      commit('SET_FIELD', field)

      return data
    })
  },

  refreshRecoveriesList({ commit, state }, { page, field }) {
    return recoveryService.getRecoveries(page, field).then(({ data }) => {
      commit('SET_META_RECOVERY', data.result)
      commit('SET_AMOUNT_FIELD', field.amount)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_USER_FIELD', field.user)
      commit('SET_PAYMENT_ID_FIELD', field.payment_id)
      commit('SET_FIELD', field)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  getRecoveryById({ commit, state }, id) {
    return recoveryService.getRecovery(id).then(({ data }) => {
      commit('SET_RECOVERY', data.result)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  validateRecoveryToPay({ commit, state }, field) {
    return recoveryService.validateRecovery(field).then(({ data }) => {
      commit('UPDATE_RECOVERY', { recovery: data.result.recovery, item: data.result.recovery })
      commit('SET_RECOVERY', data.result.recovery)
      commit('payment/ADD_PAYMENT', data.result.payment, { root: true })
      store.dispatch('statusRecovery/getStatusesRecList', { field: store.state.statusRecovery.field })

      return data
    })
  },

  // eslint-disable-next-line no-shadow
  newRecoveryEvent({ commit, state }, id) {
    return recoveryService.getRecovery(id).then(({ data }) => {
      commit('ADD_RECOVERY', data.result)

      return data
    })
  },

}

const mutations = {

  ADD_RECOVERY(state, road) {
    if (state.metaRecovery) {
      if (state.metaRecovery.per_page === state.field.paginate) state.metaRecovery.data.pop()
      state.metaRecovery.data.unshift(road)
    } else {
      store.dispatch('recovery/getRecoveriesList', { page: 1, field: state.field }).catch(err => {
        console.log(err)
      })
    }
  },

  // eslint-disable-next-line no-shadow
  UPDATE_RECOVERY(state, { recovery, item }) {
    if (state.metaRecovery) { state.metaRecovery.data.splice(state.metaRecovery.data.findIndex(t => t.id === item.id), 1, recovery) }
  },

  // eslint-disable-next-line no-shadow
  SET_GMAP_KEY(state, item) {
    state.gmapKey = item
  },
  // eslint-disable-next-line no-shadow
  SET_RECOVERY(state, item) {
    state.recovery = item
  },

  INCREMENT_REFRESH_ITEM_COUNT(state) {
    state.refreshItemCount++
  },

  SET_REFRESH_ITEM_ID(state, item) {
    state.refreshItemId = item
  },
  // eslint-disable-next-line no-shadow
  SET_OSM_ROUTE(state, item) {
    state.osmRoute = item
  },
  // eslint-disable-next-line no-shadow
  SET_DESTINATION_FIELD(state, item) {
    state.destination_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_CAR_FIELD(state, item) {
    state.car_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_USER_ID_FIELD(state, item) {
    state.user_id_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_MAX_AMOUNT_FIELD(state, item) {
    state.max_amount_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_MIN_AMOUNT_FIELD(state, item) {
    state.admin_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_PAYMENT_ID_FIELD(state, item) {
    state.payment_id_field = item
  },

  // eslint-disable-next-line no-shadow
  INCREMENT_REFRESH_LIST_COUNT(state) {
    state.refreshListCount++
  },
  // eslint-disable-next-line no-shadow
  SET_USER_FIELD(state, item) {
    state.user_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_PAGINATE_FIELD(state, paginante) {
    state.paginate_field = paginante
  },
  // eslint-disable-next-line no-shadow
  SET_AMOUNT_FIELD(state, amount) {
    state.amount_field = amount
  },
  // eslint-disable-next-line no-shadow
  SET_META_RECOVERY(state, metaRecovery) {
    state.metaRecovery = metaRecovery
  },

  // eslint-disable-next-line no-shadow
  SET_FIELD(state, field) {
    state.field = field
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
