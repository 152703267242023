import actionService from '@/store/services/actionService'

const state = {
  field: {
    label: null,
    code: null,
    paginate: 100,
  },
  label_field: null,
  code_field: null,
  paginate_field: null,
  metaAction: null,
  action: null,
}

const getters = {
  actions: state => (state.metaAction ? state.metaAction.data : []),
  current_page: state => (state.metaAction ? state.metaAction.current_page : 0),
  last_page: state => (state.metaAction ? state.metaAction.last_page : 0),
  per_page: state => (state.metaAction ? state.metaAction.per_page : 0),
}

const actions = {
  // eslint-disable-next-line no-shadow
  getActionList({ commit, state }, { page, field }) {
    if (state.metaAction && state.metaAction.current_page === page
      && state.label_field === field.label
      && state.code_field === field.code
      && state.paginate_field === field.paginate) return state.metaAction

    return actionService.getActions(page, field).then(({ data }) => {
      commit('SET_META_ACTION', data.result)
      commit('SET_LABEL_FIELD', field.label)
      commit('SET_CODE_FIELD', field.code)
      commit('SET_PAGINATE_FIELD', field.paginate)

      return data
    })
  },

  refreshActionList({ commit, state }, { page, field }) {
    return actionService.getActions(page, field).then(({ data }) => {
      commit('SET_META_ACTION', data.result)
      commit('SET_LABEL_FIELD', field.label)
      commit('SET_CODE_FIELD', field.code)
      commit('SET_PAGINATE_FIELD', field.paginate)

      return data
    })
  },
}

const mutations = {
  // eslint-disable-next-line no-shadow
  ADD_ROLE(state, action) {
    state.metaAction.data.unshift(action)
  },

  // eslint-disable-next-line no-shadow
  UPDATE_ROLE(state, { action, item }) {
    if (state.metaAction) { state.metaAction.data.splice(state.metaAction.data.findIndex(t => t.id === item.id), 1, action) }
  },
  // eslint-disable-next-line no-shadow
  SET_SETTING(state, item) {
    state.action = item
  },

  // eslint-disable-next-line no-shadow
  SET_PAGINATE_FIELD(state, paginante) {
    state.paginate_field = paginante
  },
  // eslint-disable-next-line no-shadow
  SET_LABEL_FIELD(state, label) {
    state.label_field = label
  },

  // eslint-disable-next-line no-shadow
  SET_CODE_FIELD(state, code) {
    state.code_field = code
  },
  // eslint-disable-next-line no-shadow
  SET_META_ACTION(state, metaAction) {
    state.metaAction = metaAction
  },

  // eslint-disable-next-line no-shadow
  SET_FIELD(state, field) {
    state.field = field
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
