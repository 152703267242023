import recTypeService from '@/store/services/recTypeService'

const state = {
  field: {
    keyword: null,
    paginate: 10,
  },
  keyword_field: null,
  paginate_field: null,
  metaRecType: null,
  recType: null,
}

const getters = {
  recTypes: state => (state.metaRecType ? state.metaRecType.data : []),
  current_page: state => (state.metaRecType ? state.metaRecType.current_page : 0),
  last_page: state => (state.metaRecType ? state.metaRecType.last_page : 0),
  per_page: state => (state.metaRecType ? state.metaRecType.per_page : 0),
  total: state => (state.metaRecType ? state.metaRecType.total : 0),
}

const actions = {
  // eslint-disable-next-line no-shadow
  getRecTypeList({ commit, state }, { page, field }) {
    if (state.metaRecType && state.metaRecType.current_page === page
      && state.keyword_field === field.keyword && state.paginate_field === field.paginate) return state.metaRecType

    return recTypeService.getRecTypeList(page, field).then(({ data }) => {
      commit('SET_META_RECTYPE', data.result)
      commit('SET_KEYWORD_FIELD', field.keyword)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_FIELD', field)

      return data
    })
  },

  refreshRecTypesList({ commit, state }, { page, field }) {
    return recTypeService.getRecTypeList(page, field).then(({ data }) => {
      commit('SET_META_RECTYPE', data.result)
      commit('SET_KEYWORD_FIELD', field.keyword)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_FIELD', field)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  getRecTypeById({ commit, state }, id) {
    return recTypeService.getRecType(id).then(({ data }) => {
      commit('SET_RECTYPE', data.result)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  updateRecType({ commit, state }, field) {
    return recTypeService.updateRecType(field).then(({ data }) => {
      commit('UPDATE_RECTYPE', { recType: data.result, item: field })

      return data
    })
  },

}

const mutations = {

  // eslint-disable-next-line no-shadow
  UPDATE_RECTYPE(state, { recType, item }) {
    if (state.metaRecType) { state.metaRecType.data.splice(state.metaRecType.data.findIndex(t => t.id === item.id), 1, recType) }
  },

  // eslint-disable-next-line no-shadow
  SET_RECTYPE(state, item) {
    state.recType = item
  },

  // eslint-disable-next-line no-shadow
  SET_PAGINATE_FIELD(state, paginante) {
    state.paginate_field = paginante
  },
  // eslint-disable-next-line no-shadow
  SET_KEYWORD_FIELD(state, keyword) {
    state.keyword_field = keyword
  },
  // eslint-disable-next-line no-shadow
  SET_META_RECTYPE(state, metaRecType) {
    state.metaRecType = metaRecType
  },

  // eslint-disable-next-line no-shadow
  SET_FIELD(state, field) {
    state.field = field
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
