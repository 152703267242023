import axios from '@axios'

export default {
  getStatusesList(page, field) {
    return axios.post(`admin/statuses/list?page=${page}`, field)
  },

  getStatus(id) {
    return axios.get(`admin/statuses/${id}`)
  },

  addStatus(field) {
    return axios.post('admin/statuses', field)
  },

  updateStatus(field) {
    return axios.put(`admin/statuses/${field.id}`, field)
  },

  deleteStatus(field) {
    return axios.delete(`admin/statuses/${field.id}`, field)
  },

  restoreStatus(field) {
    return axios.post(`admin/statuses/${field.id}/restore`)
  },
}
