import deviceService from '@/store/services/deviceService'

const state = {
  field: {
    is_active: null,
    agent: null,
    user_id: null,
    paginate: 10,
  },
  is_active_field: null,
  agent_field: null,
  user_id_field: null,
  paginate_field: null,
  metaDevice: null,
  device: null,
}

const getters = {
  devices: state => (state.metaDevice ? state.metaDevice.data : []),
  current_page: state => (state.metaDevice ? state.metaDevice.current_page : 0),
  last_page: state => (state.metaDevice ? state.metaDevice.last_page : 0),
  per_page: state => (state.metaDevice ? state.metaDevice.per_page : 0),
}

const actions = {
  // eslint-disable-next-line no-shadow
  getDevicesList({ commit, state }, { page, field }) {
    if (state.metaDevice && state.metaDevice.current_page === page
      && state.is_active_field === field.is_active && state.paginate_field === field.paginate
      && state.agent_field === field.agent && state.user_id_field === field.user_id) return state.metaDevice

    return deviceService.getDevices(page, field).then(({ data }) => {
      commit('SET_META_DEVICE', data.result)
      commit('SET_IS_ACTIVE_FIELD', field.is_active)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_AGENT_FIELD', field.agent)
      commit('SET_FIELD', field)
      commit('SET_USER_ID_FIELD', field.user_id)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  getDeviceById({ commit, state }, id) {
    return deviceService.getDevice(id).then(({ data }) => {
      commit('SET_DEVICE', data.result)

      return data
    })
  },

  deleteDeviceById({ commit, state }, id) {
    return deviceService.deleteDevice(id).then(({ data }) => {
      commit('UPDATE_META_DEVICE', id)

      return data
    })
  },
}

const mutations = {
  // eslint-disable-next-line no-shadow
  UPDATE_META_DEVICE(state, id) {
    if (state.metaDevice) state.metaDevice.data.splice(state.metaDevice.data.findIndex(t => t.id === id), 1)
  },

  // eslint-disable-next-line no-shadow
  SET_DEVICE(state, item) {
    state.device = item
  },
  // eslint-disable-next-line no-shadow
  SET_USER_ID_FIELD(state, item) {
    state.user_id_field = item
  },

  // eslint-disable-next-line no-shadow
  SET_AGENT_FIELD(state, item) {
    state.agent_field = item
  },
  // eslint-disable-next-line no-shadow
  SET_PAGINATE_FIELD(state, paginante) {
    state.paginate_field = paginante
  },
  // eslint-disable-next-line no-shadow
  SET_IS_ACTIVE_FIELD(state, is_active) {
    state.is_active_field = is_active
  },
  // eslint-disable-next-line no-shadow
  SET_META_DEVICE(state, metaDevice) {
    state.metaDevice = metaDevice
  },

  // eslint-disable-next-line no-shadow
  SET_FIELD(state, field) {
    state.field = field
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
