import axios from '@axios'

export default {
  getProxiMargins(page, field) {
    return axios.post(`admin/proximargins/list?page=${page}`, field)
  },

  getProxiMargin(id) {
    return axios.get(`admin/proximargins/${id}`)
  },

  addProxiMargin(field) {
    return axios.post('admin/proximargins', field)
  },

  updateProxiMargin(field) {
    return axios.put(`admin/proximargins/${field.id}`, field)
  },

  activateProxiMargin(field) {
    return axios.get(`admin/proximargins/${field.id}/activate`, field)
  },
}
