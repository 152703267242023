import axios from '@axios'

export default {
  getCampaignsList(page, field) {
    return axios.post(`platform/campaigns/list?page=${page}`, field)
  },

  getCampaign(id) {
    return axios.get(`platform/campaigns/${id}`)
  },

  addCampaign(field) {
    return axios.post('platform/campaigns', field)
  },

  updateCampaign(field) {
    return axios.put(`platform/campaigns/${field.id}`, field)
  },

  deleteCampaign(field) {
    return axios.delete(`platform/campaigns/${field.id}`, field)
  },

  restoreCampaign(field) {
    return axios.post(`platform/campaigns/${field.id}/restore`)
  },
}
