import axios from '@axios'

export default {
  getReductCodesList(page, field) {
    return axios.post(`platform/reduct_codes/list?page=${page}`, field)
  },

  getReductCode(id) {
    return axios.get(`platform/reduct_codes/${id}`)
  },

  addReductCode(field) {
    return axios.post('platform/reduct_codes', field)
  },

  updateReductCode(field) {
    return axios.put(`platform/reduct_codes/${field.id}`, field)
  },

  deleteReductCode(field) {
    return axios.delete(`platform/reduct_codes/${field.id}`, field)
  },

  restoreReductCode(field) {
    return axios.post(`platform/reduct_codes/${field.id}/restore`)
  },
}
