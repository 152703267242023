import axios from '@axios'

export default {
  getDocuments(page, field) {
    return axios.post(`platform/documents/list?page=${page}`, field)
  },

  getDocument(id) {
    return axios.get(`platform/documents/${id}`)
  },

  verifyStatus(id) {
    return axios.get(`platform/documents/verify/${id}/status`)
  },

  getStatByStatus(filter) {
    return axios.post('platform/documents/stats/status', filter)
  },

  getWithdrawByStatus(filter) {
    return axios.post('platform/documents/withdraw/status', filter)
  },

  getValueStatByStatus(filter) {
    return axios.post('platform/documents/value/status', filter)
  },

  getValueStatByReason(filter) {
    return axios.post('platform/documents/value/reason', filter)
  },

  getValueStatByValidity(filter) {
    return axios.post('platform/documents/value/validity', filter)
  },

  getGlobalStat() {
    return axios.get('platform/documents/stats/get')
  },
}
