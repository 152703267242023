const recoverypages = [
  {
    path: '/recoveries',
    name: 'recoveries',
    component: () => import('@/views/recovery/Recovery.vue'),
    meta: {
      layout: 'content',
      title: "Recouvrements",
    },
  },
  {
    path: '/recoveries/:id/show',
    name: 'showrecovery',
    component: () => import('@/views/recovery/RecoveryDetails.vue'),
    meta: {
      layout: 'content',
      title: "Détails d'un recouvrement",
    },
  },
]

export default recoverypages
