import axios from '@axios'

export default {
  getSearches(page, field) {
    return axios.post(`platform/searches/list?page=${page}`, field)
  },

  getSearch(id) {
    return axios.get(`platform/searches/${id}`)
  },

  getDistance(id) {
    return axios.get(`platform/searches/distance/${id}`)
  },

  getGMapKey() {
    return axios.get('platform/searches/gmap/key')
  },
}
