// eslint-disable-next-line import/no-cycle
import axios from '@axios'

export default {
  login(field) {
    return axios.post('/login', field)
  },
  logout(device = null) {
    if (device !== null) return axios.get(`logout/${device}`)

    return axios.get('logout')
  },
  getCurrentUser(device = null) {
    if (device !== null) return axios.get(`user/${device}`)

    return axios.get('user')
  },

  forgotPassword(email) {
    return axios.get(`forget/password/${email}`)
  },

  resetPassword(field) {
    return axios.post('password/reset/email', field)
  },

  setLang(lang) {
    return axios.get(`set/${lang}/locale`)
  },
  hasPrivilege(code) {
    return axios.get(`/admin/users/has/${code}/privilege`)
  },

  verifyFcmToken(token) {
    return axios.get(`fcm/${token}/verify`)
  },
}
