import statusService from '@/store/services/statusService'

const state = {
  field: {
    keyword: null,
    paginate: 10,
  },
  keyword_field: null,
  paginate_field: null,
  metaStatus: null,
  status: null,
}

const getters = {
  statuses: state => (state.metaStatus ? state.metaStatus.data : []),
  current_page: state => (state.metaStatus ? state.metaStatus.current_page : 0),
  last_page: state => (state.metaStatus ? state.metaStatus.last_page : 0),
  per_page: state => (state.metaStatus ? state.metaStatus.per_page : 0),
  total: state => (state.metaStatus ? state.metaStatus.total : 0),
}

const actions = {
  // eslint-disable-next-line no-shadow
  getStatusesList({ commit, state }, { page, field }) {
    if (state.metaStatus && state.metaStatus.current_page === page
      && state.keyword_field === field.keyword && state.paginate_field === field.paginate) return state.metaStatus

    return statusService.getStatusesList(page, field).then(({ data }) => {
      commit('SET_META_STATUS', data.result)
      commit('SET_KEYWORD_FIELD', field.keyword)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_FIELD', field)

      return data
    })
  },

  refreshStatusesList({ commit, state }, { page, field }) {
    return statusService.getStatusesList(page, field).then(({ data }) => {
      commit('SET_META_STATUS', data.result)
      commit('SET_KEYWORD_FIELD', field.keyword)
      commit('SET_PAGINATE_FIELD', field.paginate)
      commit('SET_FIELD', field)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  getStatusById({ commit, state }, id) {
    return statusService.getStatus(id).then(({ data }) => {
      commit('SET_STATUS', data.result)

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  updateStatus({ commit, state }, field) {
    return statusService.updateStatus(field).then(({ data }) => {
      commit('UPDATE_STATUS', { status: data.result, item: field })

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  deleteStatus({ commit, state }, field) {
    return statusService.deleteStatus(field).then(({ data }) => {
      commit('UPDATE_STATUS', { status: data.result, item: field })

      return data
    })
  },

  // eslint-disable-next-line no-shadow,no-unused-vars
  restoreStatus({ commit, state }, field) {
    return statusService.restoreStatus(field).then(({ data }) => {
      commit('UPDATE_STATUS', { status: data.result, item: field })

      return data
    })
  },

}

const mutations = {

  // eslint-disable-next-line no-shadow
  UPDATE_STATUS(state, { status, item }) {
    if (state.metaStatus) { state.metaStatus.data.splice(state.metaStatus.data.findIndex(t => t.id === item.id), 1, status) }
  },

  // eslint-disable-next-line no-shadow
  SET_STATUS(state, item) {
    state.status = item
  },

  // eslint-disable-next-line no-shadow
  SET_PAGINATE_FIELD(state, paginante) {
    state.paginate_field = paginante
  },
  // eslint-disable-next-line no-shadow
  SET_KEYWORD_FIELD(state, keyword) {
    state.keyword_field = keyword
  },
  // eslint-disable-next-line no-shadow
  SET_META_STATUS(state, metaStatus) {
    state.metaStatus = metaStatus
  },

  // eslint-disable-next-line no-shadow
  SET_FIELD(state, field) {
    state.field = field
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
