import uploadService from '@/store/services/uploadService'

const state = {
  file: {
    chemin: null,
    extension: null,
    taille: null,
    name: null,
  },
}

const getters = {
  operators: state => (state.metaOperator ? state.metaOperator.data : []),
  current_page: state => (state.metaOperator ? state.metaOperator.current_page : 0),
  last_page: state => (state.metaOperator ? state.metaOperator.last_page : 0),
  per_page: state => (state.metaOperator ? state.metaOperator.per_page : 0),
}

const actions = {
  uploadFile({ commit, state }, { model, field }) {
    return uploadService.uploadFile(model, field).then(({ data }) => {
      commit('SET_FILE', data.result)

      return data
    })
  },
}

const mutations = {
  // eslint-disable-next-line no-shadow
  SET_FILE(state, item) {
    state.file = item
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
