const roadpages = [
  {
    path: '/roads',
    name: 'roads',
    component: () => import('@/views/road/Road.vue'),
    meta: {
      layout: 'content',
      title: 'Trajets',
    },
  },
  {
    path: '/roads/:id/show',
    name: 'showroad',
    component: () => import('@/views/road/RoadDetails.vue'),
    meta: {
      layout: 'content',
      title: "Détails d'un trajet",
    },
  },
]

export default roadpages
